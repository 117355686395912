<script lang="ts">
import variables from '@/styles/_export.module.scss'
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'

@Component
export default class ScoreDetailsTable extends Vue {
  red600 = variables.red600

  @Prop()
  testId!: string

  @Prop()
  value!: boolean

  @Prop()
  width!: string

  get localValue(): boolean {
    return this.value
  }

  set localValue(value: boolean) {
    this.$emit('input', value)
  }
}
</script>

<template>
  <v-alert
    :data-cy="`error-banner-${testId}`"
    id="error-banner"
    dense
    dismissible
    :icon="false"
    type="error"
    :color="red600"
    v-model="localValue"
    :width="width"
  >
    <v-row justify="center">
      <v-col cols="auto" :data-cy="`error-row1-${testId}`" id="error-row1" class="px-0 pt-4 pb-0 ma-0 button-md-neutral"
        >There was a problem with the request. Please try again later.
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="auto" :data-cy="`error-row2-${testId}`" id="error-row2" class="px-0 pb-4 pt-0 ma-0 button-md-neutral"
        >If you continue to receive this error, contact
        <a
          class="error-banner__link"
          href="mailto:customercare@gaig.com"
          :data-cy="`error-banner-email-${testId}`"
          id="error-banner-email"
          >customercare@gaig.com</a
        >.
      </v-col>
    </v-row>
  </v-alert>
</template>
