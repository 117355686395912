import axiosInstance from '@/utils/axios-instance'
import { Recommendation } from '@/models/Recommendation'

function getPersonalChecklist(): Promise<Uint8Array> {
  return axiosInstance
    .get('/api/pdf/checklist', { responseType: 'arraybuffer' })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

function getRecommendationsTable(recommendations: Array<Recommendation>): Promise<Uint8Array> {
  return axiosInstance
    .post('/api/pdf/recommendations-table', recommendations, { responseType: 'arraybuffer' })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export { getPersonalChecklist, getRecommendationsTable }
