import axiosInstance from '@/utils/axios-instance'
import store from '@/store'
import { CyberRiskError } from '@/models/CyberRiskError'
import { User } from '@/models/User'
import { UserPreferences } from '@/models/UserPreferences'
import { handleError } from '@/utils/error-handing-utils'
import { UserProgress } from '@/models/UserProgress'

function getCurrentUser(): Promise<User | void> {
  let user!: User
  return axiosInstance
    .get('/api/user')
    .then((response) => {
      user = response.data
      return user
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'user'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    })
}

function logout(): Promise<User> {
  let user!: User
  return axiosInstance
    .get('/api/user/logout')
    .then((response) => {
      user = response.data
      return user
    })
    .catch((error) => {
      console.log(error)
      return user
    })
}

function getUserPreferences(): Promise<UserPreferences | void> {
  let userPreferences!: UserPreferences
  return axiosInstance
    .get('/api/user/preferences')
    .then((response) => {
      userPreferences = response.data
      return userPreferences
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'critical'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    })
}

function getUserProgress(): Promise<UserProgress[] | void> {
  const assessment = store.getters.getAssessment
  let userProgressArr!: UserProgress[]
  return axiosInstance
    .get('/api/user/progress', { params: { assessmentId: assessment?.id } })
    .then((response) => {
      userProgressArr = response.data
      return userProgressArr
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'warning'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    })
}

function updateUserPreferences(preferences: UserPreferences): Promise<UserPreferences | void> {
  let userPreferences!: UserPreferences
  return axiosInstance
    .put('/api/user/preferences', preferences)
    .then((response) => {
      userPreferences = response.data
      return userPreferences
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'critical'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    })
}

export { getCurrentUser, getUserPreferences, getUserProgress, logout, updateUserPreferences }
