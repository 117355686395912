<template>
  <v-sheet elevation="8">
    <div class="product-offering fill-height" :data-cy="idPrefix">
      <div class="product-offering-content">
        <div class="header-sm mt-3" :data-cy="`${idPrefix}-title`">{{ title }}</div>
        <div class="label-md-light" :data-cy="`${idPrefix}-history-text`" v-if="showHeaderHistoryText">
          {{ historyText }}
        </div>
        <div
          id="header-duration"
          :data-cy="`${idPrefix}-header-duration`"
          v-if="showHeaderDuration"
          class="product-offering-header"
        >
          <v-icon :data-cy="idPrefix + '-subtitle-icon'" size="20">$vuetify.icons.clock</v-icon>
          <div :data-cy="idPrefix + '-subtitle'" class="body-sm">{{ duration }}</div>
        </div>
        <div
          id="product-image-section"
          v-if="showProductImageSection"
          :class="`product-image ${showHeaderDuration ? '' : 'product-image-no-duration'}`"
        >
          <router-link :data-cy="`${idPrefix}-image-link`" :to="this.getStartedRoute" :target="externalRoute ? '_blank' : '_self'">
            <v-img
              v-if="showProductImage"
              :alt="altImageText"
              contain
              :data-cy="`${idPrefix}-image`"
              :src="require('@/assets/landing-page/' + image + '')"
            />
          </router-link>
          <product-offering-nist-score
            v-if="showNistScore"
            :get-started-route="this.getStartedRoute"
            :key="renderKey"
            :user-preference="userPreferences"
          />
        </div>
        <div id="company-score-section" v-if="showCompanyScore" class="company-score">
          <div class="label-md-neutral company-score-text">
            <span
              :data-cy="`${idPrefix}-score-text-1`"
              :class="`py-0 score-text--${userPreferences.companyScore.grade.toLowerCase()}`"
              >YOUR
            </span>
            <span
              :data-cy="`${idPrefix}-score-text-2`"
              :class="`py-0 score-text--${userPreferences.companyScore.grade.toLowerCase()}`"
              >SCORE
            </span>
          </div>
          <v-img
            class="clickable"
            @click="navToPage"
            contain
            :data-cy="idPrefix + '-grade-image'"
            :src="require('@/assets/scorecard-grades/factor_' + userPreferences.companyScore.grade.toLowerCase() + '.svg')"
          />
          <span
            :data-cy="`${idPrefix}-score`"
            :class="`header-md-neutral score-text--${userPreferences.companyScore.grade.toLowerCase()}`"
            >{{ userPreferences.companyScore.score }}
          </span>
        </div>
        <div v-if="!historyText" class="mb-4 product-offering-buttons">
          <v-btn
            class="button-md-neutral navy-500-background"
            dark
            :data-cy="idPrefix + '-get-started-button'"
            :to="getStartedRoute"
            :target="externalRoute ? '_blank' : '_self'"
            >Get started
          </v-btn>
          <v-btn class="button-md" @click="togglePreview" :data-cy="idPrefix + '-preview-button'" outlined
            >See preview
          </v-btn>
        </div>
        <div id="button-row-with-history" v-else class="mb-4 product-offering-history-buttons">
          <v-btn
            class="button-md-neutral navy-500-background"
            dark
            :data-cy="idPrefix + '-get-started-button'"
            :to="getStartedRoute"
            >{{ startButtonText }}
          </v-btn>
          <div v-if="showButtonRowDurationSection" class="product-offering-duration">
            <v-icon :data-cy="idPrefix + '-subtitle-icon'" size="20">$vuetify.icons.clock</v-icon>
            <div :data-cy="idPrefix + '-subtitle'" class="body-sm">{{ duration }}</div>
          </div>
          <v-btn v-else class="button-md" @click="togglePreview" :data-cy="idPrefix + '-preview-button'" outlined
            >See preview
          </v-btn>
        </div>
      </div>
      <div id="footer-section" class="product-offering-footer px-3">
        <div class="body-md text-align-center pa-2" :data-cy="idPrefix + '-footer-text'">{{ footerText }}</div>
        <div class="body-sm-light text-align-center" :data-cy="idPrefix + '-footer-subtext'" v-if="footerSubtext">
          {{ footerSubtext }}
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import ProductOfferingNistScore from '@/components/ProductOfferingNistScore.vue'
import store from '@/store'
import { DateUtils } from '@/utils/date-utils'
import { Prop, Vue } from 'vue-property-decorator'
import { UserPreferences } from '@/models/UserPreferences'

@Component({
  components: { ProductOfferingNistScore }
})
export default class ProductOfferingThreeWide extends Vue {
  @Prop()
  altImageText!: string

  @Prop()
  dataCyPrefix!: string

  @Prop()
  duration!: string

  @Prop()
  image!: string

  @Prop()
  footerText!: string

  @Prop()
  footerSubtext!: string

  @Prop()
  getStartedRoute!: string

  @Prop()
  externalRoute!: boolean

  @Prop()
  historyTextPrefix!: string

  @Prop()
  title!: string

  @Prop()
  productType!: string

  dateUtils = new DateUtils()
  renderKey = 0

  get showButtonRowDurationSection(): boolean {
    if (this.productType !== 'assessment') {
      return !!this.historyText
    }

    return !!this.userPreferences.nistMaturityScore
  }

  get historyText(): string {
    let previousDate = ''
    let text = ''
    switch (this.productType) {
      case 'insights':
        previousDate = this.userPreferences.lastViewedInsights
        break
      case 'scorecard':
        previousDate = this.userPreferences.lastViewedScorecard
        break
      case 'assessment':
        previousDate = this.userPreferences.lastUpdatedAssessment
        break
    }

    if (previousDate) {
      text = this.historyTextPrefix + this.dateUtils.getLastVisitedDateString(previousDate)
    }

    return text
  }

  get idPrefix(): string {
    return this.dataCyPrefix + this.productType
  }

  get showCompanyScore(): boolean {
    if (!this.userPreferences.companyScore || !this.historyText) {
      return false
    }

    return this.userPreferences.companyScore.grade != null && this.productType === 'scorecard'
  }

  get showHeaderDuration(): boolean {
    return (
      !!this.duration &&
      (!this.historyText || (this.productType === 'assessment' && !this.userPreferences.nistMaturityScore))
    )
  }

  get hasCompletedAssessment(): boolean {
    if (this.productType !== 'assessment') return false

    return !!this.userPreferences.nistMaturityScore
  }

  get showHeaderHistoryText(): boolean {
    return (!!this.historyText && this.productType !== 'assessment') || this.hasCompletedAssessment
  }

  get showProductImage(): boolean {
    return (
      !this.historyText ||
      (this.productType === 'assessment' && !this.userPreferences.nistMaturityScore) ||
      this.productType === 'insights'
    )
  }

  get showProductImageSection(): boolean {
    return !this.showCompanyScore || this.productType !== 'scorecard'
  }

  get showNistScore(): boolean {
    return !!this.historyText && this.productType === 'assessment' && !!this.userPreferences.nistMaturityScore
  }

  get startButtonText(): string {
    let text = ''
    switch (this.productType) {
      case 'insights':
        text = 'Review'
        break
      case 'scorecard':
        text = 'Reassess'
        break
      case 'assessment':
        text = this.userPreferences.nistMaturityScore ? 'Reassess' : 'Resume'
        break
    }
    return text
  }

  get userPreferences(): UserPreferences {
    return store.getters.getUserPreferences
  }

  navToPage(): void {
    this.$router.push(this.getStartedRoute)
  }

  togglePreview(): void {
    this.$emit('togglePreview', this.productType)
  }

  mounted(): void {
    this.renderKey++
  }
}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

.score-text {
  &--a {
    color: $scorecard-grade-a;
  }

  &--b {
    color: $scorecard-grade-b;
  }

  &--c {
    color: $scorecard-grade-c;
  }

  &--d {
    color: $scorecard-grade-d;
  }

  &--f {
    color: $scorecard-grade-f;
  }
}

.your-score-text {
  margin-left: -6px;
}

.product-offering {
  display: flex;
  flex-direction: column;
}

.product-offering-header {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 8px;
}

.product-offering-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px 24px 0 24px;
  height: 100%;
}

.product-offering-footer {
  display: flex;
  padding: 12px 0;
  flex-direction: column;
  justify-content: center;
  background-color: $brand-shades-navy-50;
  min-height: 100px;
  max-height: 100px;
  flex-basis: 100%;
}

.product-image {
  display: flex;
}

.product-image-no-duration {
  padding-top: 28px;
}

.company-score {
  display: flex;
  align-items: center;
  gap: 12px;
}

.company-score-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-offering-buttons {
  display: flex;
  gap: 24px;
  margin-top: auto;
}

.product-offering-history-buttons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.product-offering-duration {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
