interface ICollaboratorState {
  questionSheetKey: number
}

const state: ICollaboratorState = {
  questionSheetKey: 0
}

const getters = {
  getQuestionSheetKey: (state: ICollaboratorState): number => {
    return state.questionSheetKey
  }
}
const mutations = {
  setQuestionSheetKey(state: ICollaboratorState, params: number): void {
    state.questionSheetKey = params
  }
}

export default {
  state,
  getters,
  mutations
}
