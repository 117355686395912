<template>
  <div class="pa-4 text-center">
    <v-dialog
      v-model="showNoTeamMembersDialog"
      @click:outside="toggleNoTeamMembersModal"
      height="385px"
      width="852px"
      data-cy="no-team-members-modal"
    >
      <v-card>
        <v-sheet class="pa-3" data-cy="no-team-members-modal-sheet">
          <v-row id="no-team-members-modal-title" align="stretch" class="mt-3">
            <v-col cols="11">
              <div class="header-sm" data-cy="no-team-members-modal-title">
                No Team Found
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col data-cy="no-team-member-x-button">
              <v-icon @click="toggleNoTeamMembersModal">X</v-icon>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pt-1 d-flex" cols="9">
              <div class="body-sm text-align-center" data-cy="no-team-members-modal-title-subtext">
                You have not added any collaborators to your team
              </div>
            </v-col>
          </v-row>

          <v-card-text>
            <v-row>
              <v-col class="body-lg-semi-bold" data-cy="no-team-members-modal-text">
                Adding collaborators to your team will allow you to assign questions/categories to them directly from the
                Roadmap. The Manage Team page lets you add and remove team members, edit notes, and more.
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row class="mb-4">
              <v-col cols="auto">
                <v-btn
                  class="button-md-neutral assign-button"
                  id="go-to-manage-team-button"
                  dark
                  data-cy="go-to-manage-team-button"
                  @click="goToManageTeams"
                  >Go To Manage Team Page
                </v-btn>
              </v-col>

              <v-col cols="auto">
                <v-btn
                  class="button-md-neutral cancel-button"
                  id="no-team-members-modal-cancel-button"
                  data-cy="no-team-members-modal-cancel-button"
                  @click="toggleNoTeamMembersModal"
                  >Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-sheet>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import ManageTeams from '@/views/ManageTeams.vue'
import router from '@/router/router'

@Component({
  computed: {
    ManageTeams() {
      return ManageTeams
    }
  }
})
export default class NoTeamMembersModal extends Vue {
  @Prop()
  isOpen!: boolean

  showNoTeamMembersDialog = false

  @Watch('isOpen')
  onPropertyValueChanged(val: boolean): void {
    this.showNoTeamMembersDialog = val
  }

  toggleNoTeamMembersModal(): void {
    this.showNoTeamMembersDialog = !this.showNoTeamMembersDialog
    this.$parent?.$emit('toggleNoTeamMembersModal')
  }

  goToManageTeams(): void {
    this.toggleNoTeamMembersModal()
    router.push({ name: 'ManageTeams' })
  }
}
</script>
