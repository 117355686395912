import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { RouterUtils } from '@/utils/router-utils'
import LandingPage from '@/views/LandingPage.vue'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: { name: 'LandingPage' },
    meta: {
      isProtected: false
    }
  },
  {
    path: '/landing',
    name: 'LandingPage',
    meta: {
      isProtected: false
    },
    component: LandingPage
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    meta: {
      isProtected: false
    },
    component: () => import(/* webpackChunkName: 'AccessDenied' */ '@/views/AccessDenied.vue')
  },
  {
    path: '/error-page',
    name: 'ErrorPage',
    meta: {
      isProtected: false
    },
    component: () => import(/* webpackChunkName: 'ErrorPage' */ '@/views/ErrorPage.vue')
  },
  {
    path: '/app/loading',
    name: 'LoadingPage',
    meta: {
      isProtected: true,
      collaboratorAccessible: true
    },
    component: () => import(/* webpackChunkName: 'LoadingPage' */ '@/views/LoadingPage.vue')
  },
  {
    path: '/app/roadmap/recommendations',
    name: 'RoadmapRecommendations',
    meta: {
      isProtected: true
    },
    props: true,
    component: () => import(/* webpackChunkName: 'RoadmapRecommendations' */ '@/views/RoadmapRecommendations.vue')
  },
  {
    path: '/app/roadmap/welcome',
    name: 'RoadmapWelcome',
    meta: {
      isProtected: true
    },
    component: () => import(/* webpackChunkName: 'RoadmapWelcome' */ '@/views/RoadmapWelcome.vue')
  },
  {
    path: '/app/roadmap/overview',
    name: 'RoadmapOverview',
    meta: {
      isProtected: true
    },
    component: () => import(/* webpackChunkName: 'RoadmapOverview' */ '@/views/RoadmapOverview.vue')
  },
  {
    path: '/app/roadmap/identify',
    name: 'RoadmapIdentify',
    meta: {
      isProtected: true
    },
    props: true,
    component: () => import(/* webpackChunkName: 'RoadmapIdentify' */ '@/views/RoadmapIdentify.vue')
  },
  {
    path: '/app/roadmap/protect',
    name: 'RoadmapProtect',
    meta: {
      isProtected: true
    },
    props: true,
    component: () => import(/* webpackChunkName: 'RoadmapProtect' */ '@/views/RoadmapProtect.vue')
  },
  {
    path: '/app/roadmap/detect',
    name: 'RoadmapDetect',
    meta: {
      isProtected: true
    },
    props: true,
    component: () => import(/* webpackChunkName: 'RoadmapDetect' */ '@/views/RoadmapDetect.vue')
  },
  {
    path: '/app/roadmap/respond',
    name: 'RoadmapRespond',
    meta: {
      isProtected: true
    },
    props: true,
    component: () => import(/* webpackChunkName: 'RoadmapRespond' */ '@/views/RoadmapRespond.vue')
  },
  {
    path: '/app/roadmap/recover',
    name: 'RoadmapRecover',
    meta: {
      isProtected: true
    },
    props: true,
    component: () => import(/* webpackChunkName: 'RoadmapRecover' */ '@/views/RoadmapRecover.vue')
  },
  {
    path: '/app/roadmap/govern',
    name: 'RoadmapGovern',
    meta: {
      isProtected: true
    },
    props: true,
    component: () => import(/* webpackChunkName: 'RoadmapGovern' */ '@/views/RoadmapGovern.vue')
  },
  {
    path: '/app/roadmap/results',
    name: 'RoadmapResults',
    meta: {
      isProtected: true
    },
    component: () => import(/* webpackChunkName: 'RoadmapResults' */ '@/views/RoadmapResults.vue')
  },
  {
    path: '/app/welcome',
    name: 'Welcome',
    meta: {
      isProtected: true,
      collaboratorAccessible: true
    },
    component: () => import(/* webpackChunkName: 'Welcome' */ '@/views/Welcome.vue')
  },
  {
    path: '/app/scorecard',
    name: 'Scorecard',
    meta: {
      isProtected: true,
      collaboratorAccessible: false
    },
    component: () => import(/* webpackChunkName: 'Scorecard' */ '@/views/Scorecard.vue')
  },
  {
    path: '/insights',
    name: 'EmployeeInsights',
    meta: {
      isProtected: false
    },
    component: () => import(/* webpackChunkName: 'EmployeeInsights' */ '@/views/EmployeeInsights.vue')
  },
  {
    path: '/app/manage-teams',
    name: 'ManageTeams',
    meta: {
      isProtected: true
    },
    component: () => import(/* webpackChunkName: 'ManageEmployees' */ '@/views/ManageTeams.vue')
  },
  {
    path: '/app/collaborator-questions',
    name: 'CollaboratorQuestions',
    meta: {
      isProtected: true,
      collaboratorAccessible: true
    },
    component: () => import(/* webpackChunkName: 'CollaboratorQuestions' */ '@/views/CollaboratorQuestions.vue')
  },
  {
    path: '/abira',
    name: 'Abira',
    beforeEnter() {
      window.location.replace("https://abirasecurity.com/great-american-insurance-group/")
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  //session storage remove categoryId
  const routerUtils = new RouterUtils()
  if (to.name === 'AccessDenied') {
    routerUtils.logout(to, from, next)
  } else if (to.name === 'ErrorPage') {
    routerUtils.navigateToError(to, from, next)
  } else {
    routerUtils.navigateToRoute(to, from, next, routes)
  }
})

export default router
