import axiosInstance from '@/utils/axios-instance'
import store from '@/store'
import { clearErrors, handleError } from '@/utils/error-handing-utils'
import { AssessmentProgress } from '@/models/AssessmentProgress'
import { AssessmentScore } from '@/models/AssessmentScore'
import { Assessment } from '@/models/Assessment'
import { CompanyScore } from '@/models/CompanyScore'
import { CyberRiskError } from '@/models/CyberRiskError'
import { DomainData } from '@/models/DomainData'
import { Industry } from '@/models/Industry'
import { NistFunction } from '@/models/NistFunction'
import { Recommendation } from '@/models/Recommendation'

function getDomains(): Promise<DomainData[] | void> {
  let domains!: DomainData[]
  return axiosInstance
    .get('/api/scorecard/domains')
    .then((response) => {
      domains = response.data
      return domains
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'scorecard'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    })
}

function getNistFunctions(): Promise<NistFunction[]> {
  let nistFunctions!: NistFunction[]
  return axiosInstance.get('/api/functions').then((response) => {
    nistFunctions = response.data
    return nistFunctions
  })
}

function getRecommendations(): Promise<Recommendation[]> {
  let recommendations!: Recommendation[]
  return axiosInstance.get('/api/recommendations').then((response) => {
    recommendations = response.data
    return recommendations
  })
}

function getScore(): Promise<AssessmentScore | void> {
  let assessmentScore!: AssessmentScore
  return axiosInstance
    .get('/api/assessment/score')
    .then((response) => {
      assessmentScore = response.data
      return assessmentScore
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'app'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    })
}

function getScorecard(domain: string): Promise<Uint8Array> {
  return axiosInstance
    .get('/api/scorecard/' + domain, { responseType: 'arraybuffer' })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'scorecard'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    })
}

function getCompanyScore(domain: string): Promise<CompanyScore | void> {
  let score!: CompanyScore
  return axiosInstance
    .get('/api/scorecard/score/' + domain)
    .then((response) => {
      score = response.data
      return score
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'warning'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    })
}

function getProgress(assessment: Assessment): Promise<AssessmentProgress | void> {
  let assessmentProgress!: AssessmentProgress
  return axiosInstance
    .post('/api/assessment/progress', assessment)
    .then((response) => {
      const cyberRiskError = store.getters.getCyberRiskError
      if (cyberRiskError?.type != 'critical') {
        clearErrors()
      }
      assessmentProgress = response.data
      return assessmentProgress
    })
    .catch((error) => {
      const cyberRiskError = new CyberRiskError()

      cyberRiskError.type = 'warning'
      cyberRiskError.method = 'getProgress'
      cyberRiskError.statusCode = error.response?.status

      handleError(cyberRiskError)
    })
}

function getIndustries(): Promise<Industry[]> {
  let industries!: Industry[]
  return axiosInstance.get('/api/industries').then((response) => {
    industries = response.data
    return industries
  })
}

export {
  getCompanyScore,
  getDomains,
  getIndustries,
  getNistFunctions,
  getProgress,
  getRecommendations,
  getScore,
  getScorecard
}
