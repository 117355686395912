import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=40f73f8f&scoped=true"
import script from "./AppFooter.vue?vue&type=script&lang=ts"
export * from "./AppFooter.vue?vue&type=script&lang=ts"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=40f73f8f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f73f8f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VDivider,VFooter,VImg,VRow,VSpacer})
