<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.64344 11.69C2.29534 11.69 1.07996 10.878 0.563978 9.6325C0.0479944 8.38705 0.332999 6.95341 1.28611 6.00001L2.70078 4.58535L3.64344 5.52801L2.22944 6.94201C1.72415 7.44731 1.52681 8.18379 1.71176 8.87403C1.89671 9.56427 2.43585 10.1034 3.1261 10.2884C3.81634 10.4733 4.55282 10.276 5.05811 9.77068L6.47211 8.35668L7.41478 9.30001L6.00078 10.714C5.37683 11.341 4.52802 11.6925 3.64344 11.69ZM4.11478 8.82801L3.17211 7.88535L7.88611 3.17135L8.82878 4.11401L4.11544 8.82735L4.11478 8.82801ZM9.30078 7.41401L8.35744 6.47135L9.77145 5.05735C10.2836 4.55357 10.4862 3.81379 10.3023 3.11933C10.1183 2.42488 9.576 1.88245 8.88158 1.69831C8.18717 1.51417 7.44735 1.71663 6.94344 2.22868L5.52878 3.64268L4.58611 2.70001L6.00078 1.28535C7.30417 -0.00667572 9.40676 -0.00207147 10.7045 1.29565C12.0022 2.59336 12.0068 4.69596 10.7148 5.99935L9.30078 7.41335V7.41401Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'
import Vue from 'vue'

@Component
export default class DisabledLinkIcon extends Vue {
  iconColor = variables.navy100
}
</script>
