import { User } from '@/models/User'
import { UserPreferences } from '@/models/UserPreferences'

interface IUserState {
  currentUser: User,
  userPreferences: UserPreferences
}

const state: IUserState = {
  currentUser: new User(),
  userPreferences: new UserPreferences()
}

const getters = {
  getCurrentUser: (state: IUserState): User => {
    return state.currentUser
  },
  getUserPreferences: (state: IUserState): UserPreferences => {
    return state.userPreferences
  }
}

const mutations = {
  setCurrentUser: (state: IUserState, params: User): void => {
    state.currentUser = params
  },
  setUserPreferences: (state: IUserState, userPreferences: UserPreferences): void => {
    state.userPreferences = userPreferences
  }
}

export default {
  state,
  getters,
  mutations
}
