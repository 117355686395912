<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22.3655" height="22" rx="11" fill="white" />
    <rect x="1" y="1" width="22.3655" height="22" rx="11" stroke="#CCD4DC" stroke-width="2" />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class OptionSelectDefaultIcon extends Vue {}
</script>
