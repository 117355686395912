<template>
  <svg v-if="hasNext" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
      fill="#F2F4F6"
    />
    <path
      d="M20.414 26.01L18.999 24.597L23.599 19.997L18.999 15.397L20.414 13.99L26.424 20L20.415 26.01H20.414ZM14.989 26.01L13.575 24.597L18.175 19.997L13.575 15.404L14.989 13.99L21 20L14.99 26.01H14.989Z"
      fill="#00274E"
    />
    <path
      d="M4 2H36V-2H4V2ZM38 4V36H42V4H38ZM36 38H4V42H36V38ZM2 36V4H-2V36H2ZM4 38C2.89543 38 2 37.1046 2 36H-2C-2 39.3137 0.686293 42 4 42V38ZM38 36C38 37.1046 37.1046 38 36 38V42C39.3137 42 42 39.3137 42 36H38ZM36 2C37.1046 2 38 2.89543 38 4H42C42 0.686291 39.3137 -2 36 -2V2ZM4 -2C0.686291 -2 -2 0.686293 -2 4H2C2 2.89543 2.89543 2 4 2V-2Z"
      fill="#CCD4DC"
    />
  </svg>
  <svg v-else viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 0 36 C 0 38.209 1.791 40 4 40 L 36 40 C 38.209 40 40 38.209 40 36 L 40 4 C 40 1.791 38.209 0 36 0 L 4 0 C 1.791 0 0 1.791 0 4 L 0 36 Z"
      fill="#E6E9ED"
      transform="matrix(0, 1, -1, 0, 40, 0)"
    />
    <path
      d="M 19.585 13.99 L 13.575 20 L 19.585 26.01 L 21 24.596 L 16.4 19.996 L 21 15.396 L 19.586 13.99 L 19.585 13.99 Z M 25.01 13.99 L 18.999 20 L 25.01 26.01 L 26.424 24.596 L 21.824 19.996 L 26.424 15.396 L 25.011 13.99 L 25.01 13.99 Z"
      fill="#99A9B8"
      transform="matrix(-1, 0, 0, -1, 39.999001, 40)"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class NextFunctionNavIcon extends Vue {
  get hasNext(): boolean {
    const identify = 'RoadmapIdentify'
    const protect = 'RoadmapProtect'
    const detect = 'RoadmapDetect'
    const respond = 'RoadmapRespond'
    const recover = 'RoadmapRecover'

    const currentRoute = this.$router.currentRoute
    const routeNames = [identify, protect, detect, respond, recover]
    let hasNext = false
    for (let i = 0; i < routeNames.length; i++) {
      const routeName = routeNames[i]
      if (routeName === currentRoute.name) {
        hasNext = !!routeNames[i + 1]
        break
      }
    }
    return hasNext
  }
}
</script>
