<template>
  <v-container class="px-0" fluid>
    <template>
      <v-row class="mb-4 px-3" id="header">
        <v-container class="pa-0" data-cy="manage-team-header" fluid>
          <v-row align="center" class="mt-3" id="title-row">
            <v-col class="header-md" cols="auto" data-cy="header-title">Manage Team</v-col>
            <v-spacer/>
            <v-col class="header-xs-light text-uppercase" cols="auto">
              <v-btn
                  class="button-md"
                  data-cy="back-to-home-header-button"
                  id="back-to-home-header-button"
                  outlined
                  :to="{ name: backToHomeRouteName }"
              >Back to home
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col cols="auto">
              <template>
                <v-btn
                    class="button-md-neutral navy-500-background"
                    dark
                    data-cy="add-team-member-button"
                    variant="tonal"
                    @click="toggleAddTeamMemberModal()"
                >+ Add Team Member
                </v-btn>
              </template>
              <add-team-member-model :is-open="isPreviewOpen" v-on:toggleAddTeamMemberModal="toggleAddTeamMemberModal"/>
            </v-col>
            <v-col cols="auto" v-if="teamMembersExists">
              <v-btn
                  class="button-md"
                  data-cy="assign-question-button"
                  outlined
                  :to="{name: 'RoadmapIdentify'}"
              >Assign Question
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="teamMembersExists">
            <v-expansion-panels v-model="panel" multiple focusable :key="panelKey">
              <v-expansion-panel :v-model="panel" v-for="(teamMember, i) in teamMembersList" :key="i">
                <v-expansion-panel-header
                    :data-cy="'accordion-header-' + i"
                    :class="{ 'blue-grey lighten-4': i < teamMembersList.length }"
                >
                  <template v-slot:actions>
                    <v-icon class="icon">$expand</v-icon>
                  </template>
                  <span class="header">
                    <v-container class="pa-0 ma-0">
                      <v-row dense align="center" justify="space-between">
                        <v-col class="ml-4" cols="auto">
                        {{ teamMember.firstName + ' ' + teamMember.lastName + ', ' + teamMember.email }}
                        </v-col>
                        <v-col cols="auto" class="mr-4">
                           <v-tooltip
                               top
                               nudge-left="150"
                               nudge-bottom="45"
                               :value="showDeleteDisabledTooltip"
                               :key="i"
                               :open-on-hover="true"
                               :color="navy500"
                               max-width="15.625rem"
                           >
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                v-bind="attrs"
                                v-on="teamMemberDeleteIsAvailable(teamMember) ? '' : on"
                                :data-cy="`delete-icon-${i}`"
                                :class="`${teamMemberDeleteIsAvailable(teamMember) ? 'delete-icon-active' : 'delete-icon-inactive'}`"
                                @click="openDeleteModal(teamMember)"
                                :src="getDeleteIconSrc(teamMember)"
                              />
                            </template>
                            <span class="body-sm-white" data-cy="delete-tooltip-text">
                              Newly created team members cannot be deleted within the first 2 hours of creation.
                            </span>
                         </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content :data-cy="`accordion-content-${i}`">
                  <v-container
                      :data-cy="`assigned-questions-table-${i}`"
                      v-if="getAssignedQuestionsForTeamMember(teamMember).length > 0"
                  >
                    <v-row>
                      <v-col class="col-2">Category</v-col>
                      <v-col class="col-3">Question</v-col>
                      <v-col class="col-2">Notes</v-col>
                      <v-col class="col-2">Status</v-col>
                      <v-col class="col-2">Actions</v-col>
                    </v-row>
                    <v-divider/>
                    <div v-for="(assignedQuestion, j) in getAssignedQuestionsForTeamMember(teamMember)"
                         :key="j">
                      <v-row :data-cy="`question-table-row-${i}-${j}`">
                        <v-col class="col-2" :data-cy="`question-table-category-${i}-${j}`">
                          {{ getCategoryById(assignedQuestion.categoryId) }}
                        </v-col>
                        <v-col class="col-3" :key="questionKey" :data-cy="`question-table-question-${i}-${j}`">
                          {{ assignedQuestion.questionText }}
                        </v-col>
                        <v-col class="col-2" :data-cy="`question-table-notes-${i}-${j}`">
                          {{ findNoteForQuestion(assignedQuestion, teamMember.answers) }}
                        </v-col>
                        <v-col class="col-2" :data-cy="`question-table-status-${i}-${j}`"
                        ><span
                            :class="`${ isQuestionSubmitted(assignedQuestion, teamMember) ? 'complete' : 'in-progress' }`">
                          {{ isQuestionSubmitted(assignedQuestion, teamMember) ? 'Complete' : 'In Progress' }}
                        </span>
                        </v-col>
                        <v-col class="col-2 text-no-wrap">
                          <v-btn
                              @click="reassignQuestion(assignedQuestion.id)"
                              :data-cy="`reassign-button-${i}-${j}`"
                              class="button-white"
                          >
                            <v-img class="button-chevron" :src="require('@/assets/icons/chevron-duo-right.svg')"/>
                            <v-img class="img-user" :src="require('@/assets/icons/userIcon.svg')"/>
                            Reassign
                          </v-btn>
                          <v-btn
                              v-if="!isQuestionSubmitted(assignedQuestion, teamMember)"
                              :data-cy="`remove-button-${i}-${j}`"
                              class="button-white"
                              @click="toggleRemoveAssignmentModal(assignedQuestion.id)"
                          >Remove
                          </v-btn>
                          <v-btn
                              v-else
                              :data-cy="`accept-button-${i}-${j}`"
                              class="accept-button"
                              @click="openAcceptDialog(teamMember, assignedQuestion)"
                          >
                            <v-img :src="require('@/assets/icons/circle-check.svg')"/>
                            Accept
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider/>
                    </div>
                    <remove-assignment-modal
                        :isOpen="showRemoveAssignmentModal"
                        :questionId="questionIdToRemove"
                        v-on:toggleRemoveAssignmentModal="toggleRemoveAssignmentModal($event)"
                    />
                  </v-container>
                  <empty-state
                      v-if="!hasAssignedQuestions(teamMember)"
                      subtext1="THERE ARE NO ASSIGNMENTS TO DISPLAY"
                      subtext2="When a question or category is assigned, it will show here."
                      :identifierForFolderLogo="'accordion-content-folder-logo-' + i"
                      :identifierForFirstLine="'accordion-content-subtitle-first-line-' + i"
                      :identifierForSecondLine="'accordion-content-subtitle-second-line-' + i"
                      sourceImage="emptyFolder-wCircle"
                  ></empty-state>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-divider v-if="!teamMembersExists" class="section-divider mt-2 mx-0"/>
          <v-row v-if="!teamMembersExists" align="stretch" class="mt-3">
            <v-col cols="12" class="d-flex justify-center">
              <v-img
                  class="shrink mr-2"
                  contain
                  data-cy="company-header-logo"
                  :src="require('@/assets/icons/userIcon.svg')"
                  width="75px"
              />
            </v-col>
          </v-row>
          <v-row v-if="!teamMembersExists" align="stretch" class="mt-3">
            <v-col class="pa-0 d-flex justify-center" cols="12">
              <div class="body-md body-sm-semibold text-align-center" data-cy="manage-teams-subtitle-1">
                THERE ARE NO TEAM MEMBERS TO DISPLAY
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!teamMembersExists" align="stretch">
            <v-col class="pt-1 d-flex justify-center" cols="12">
              <div class="body-sm-light text-align-center">When a team member is added, they will show here.</div>
            </v-col>
          </v-row>
          <v-divider v-if="!teamMembersExists" class="section-divider mt-8 mx-0"/>
        </v-container>
      </v-row>
    </template>
    <v-dialog
        v-if="showAcceptModal"
        data-cy="accept-assignment-confirmation-modal"
        height="15.8125rem"
        :value="showAcceptModal"
        width="48.5625rem"
        @click:outside="showAcceptModal = false"
    >
      <v-card>
        <v-sheet data-cy="accept-assignment-confirmation-modal-sheet">
          <v-row class="justify-center align-center">
          </v-row>
          <v-row class="pa-4 modal-header">
            <v-col cols="11">
              <div class="header-sm" data-cy="accept-assignment-confirmation-modal-title">
                Accept and remove this assignment
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-icon data-cy="accept-assignment-confirmation-modal-close-icon" @click="showAcceptModal = false">X
              </v-icon>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mt-6">
                <div class="body-lg" data-cy="accept-assignment-confirmation-modal-body">
                  Accepting this assignment will remove it from all views
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row class="pl-2 pb-2">
              <v-col cols="auto">
                <v-btn
                    class="button-md-neutral navy-500-background"
                    dark
                    data-cy="accept-assignment-confirmation-modal-accept-button"
                    @click="acceptCurrentAssignment"
                >Accept
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                    class="button-md-neutral"
                    outlined
                    data-cy="accept-assignment-confirmation-modal-cancel-button"
                    @click="showAcceptModal = false"
                >Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-dialog
        v-if="showDeleteModal"
        data-cy="delete-collaborator-modal"
        height="12.6875rem"
        :value="showDeleteModal"
        width="28.5rem"
        @click:outside="showDeleteModal = false"
    >
      <v-card>
        <v-sheet data-cy="delete-collaborator-modal-sheet">
          <v-row class="justify-center align-center">
          </v-row>
          <v-row class="px-4 pt-4 modal-header">
            <v-col cols="10">
              <div class="header-sm" data-cy="delete-collaborator-modal-title">
                Delete Collaborator
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-icon data-cy="delete-collaborator-modal-close-icon" @click="showDeleteModal = false">X
              </v-icon>
            </v-col>
          </v-row>
          <v-row class="modal-header mb-4">
            <v-col cols="12" class="ml-4 pt-0 body-sm" data-cy="delete-collaborator-modal-body">
              Remove {{ collaboratorToDelete.firstName }} {{ collaboratorToDelete.lastName }} from my team
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row class="pl-2 pb-2">
              <v-col cols="auto">
                <v-btn
                    class="button-md-neutral navy-500-background"
                    dark
                    data-cy="delete-collaborator-modal-delete-button"
                    @click="deleteTeamMember"
                >Delete
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                    class="button-md-neutral"
                    outlined
                    data-cy="delete-collaborator-modal-cancel-button"
                    @click="showDeleteModal = false"
                >Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-sheet>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import AddTeamMemberModel from '@/components/AddTeamMemberModal.vue'
import Component from 'vue-class-component'
import EmptyState from '@/components/EmptyState.vue'
import NavBarTile from '@/components/NavBarTile.vue'
import RemoveAssignmentModal from '@/components/RemoveAssignmentModal.vue'
import store from '@/store'
import variables from '@/styles/_export.module.scss'
import {AssessmentAnswer} from '@/models/AssessmentAnswer'
import {AssessmentStorageService} from '@/service/AssessmentStorageService'
import {Assessment} from '@/models/Assessment'
import {DateTime} from 'luxon'
import {NistFunction} from '@/models/NistFunction'
import {Question} from '@/models/Question'
import {TeamMember} from '@/models/TeamMember'
import {Vue, Watch} from 'vue-property-decorator'
import {deleteTeamMemberFromIDV} from '@/service/TeamMemberService'
import {findQuestionById} from '@/utils/question-utils'

@Component({
  components: {AddTeamMemberModel, NavBarTile, EmptyState: EmptyState, RemoveAssignmentModal}
})
export default class ManageTeams extends Vue {
  assessmentStorageService = new AssessmentStorageService()
  collaboratorToDelete: TeamMember | null = null
  showDeleteModal = false
  isPreviewOpen = false
  panel = []
  questionIdToRemove = 0
  showAcceptModal = false
  showRemoveAssignmentModal = false
  currentTeamMember: TeamMember | null = null
  currentAssignedQuestion: Question | null | undefined = null
  panelKey = 0
  showDeleteDisabledTooltip = false
  navy500 = variables.navy500

  openAcceptDialog(teamMember: TeamMember, question: Question): void {
    this.currentTeamMember = teamMember
    this.currentAssignedQuestion = question
    this.showAcceptModal = true
  }

  openDeleteModal(teamMember: TeamMember): void {
    if (this.teamMemberDeleteIsAvailable(teamMember)) {
      this.collaboratorToDelete = teamMember
      this.showDeleteModal = true
    }
  }

  getDeleteIconSrc(teamMember: TeamMember): string {
    return this.teamMemberDeleteIsAvailable(teamMember)
        ? require('@/assets/icons/trash-full.svg')
        : require('@/assets/icons/trash-full-inactive.svg')
  }

  acceptCurrentAssignment(): void {
    if (!this.currentTeamMember || !this.currentAssignedQuestion) {
      this.showAcceptModal = false
      return
    }

    const question = this.currentAssignedQuestion
    const teamMember = this.currentTeamMember
    let assessment = this.assessment

    const answers = teamMember.answers.filter(answer =>
        answer.questionId === question.id ||
        question.followUpQuestions?.some(followUp => followUp.id === answer.questionId)
    )

    if (answers.length > 0) {
      answers.forEach(answer => {
        assessment = this.assessmentStorageService.updateAssessmentWithAnswer(assessment, answer)
      })

      teamMember.answers = teamMember.answers.filter(answer =>
          !answers.includes(answer)
      )
    }

    const assignmentIndex = teamMember.collaboratorAssignments.findIndex(a => a.primaryQuestionId === question.id)
    if (assignmentIndex !== -1) {
      teamMember.collaboratorAssignments.splice(assignmentIndex, 1)
    }

    const teamMemberIndex = assessment.teamMembers.findIndex(t => t.email === teamMember.email)
    if (teamMemberIndex !== -1) {
      assessment.teamMembers.splice(teamMemberIndex, 1, teamMember)
    }

    store.commit('setAssessment', assessment)
    this.assessmentStorageService.updateAssessment(assessment)
    this.showAcceptModal = false
  }

  deleteTeamMember(): void {
    if (this.collaboratorToDelete) {
      if (this.teamMemberDeleteIsAvailable(this.collaboratorToDelete)) {
        deleteTeamMemberFromIDV(this.collaboratorToDelete.email).then(() => {
          this.assessmentStorageService.findAssessment().then((response) => {
            if (response?.id) {
              store.commit('setAssessment', response)
            }
          })
        }).finally(() => {
          ++this.panelKey
          this.showDeleteModal = false
        })
      }
    }
  }

  findNoteForQuestion(question: Question, answers: Array<AssessmentAnswer>): string {
    return answers.find(answer => answer.questionId === question.id)?.note ?? ''
  }

  toggleAddTeamMemberModal(): void {
    this.isPreviewOpen = !this.isPreviewOpen
  }

  get backToHomeRouteName(): string {
    const user = store.getters.getCurrentUser
    return user.authenticated && user.authorized ? 'Welcome' : 'LandingPage'
  }

  get assessment(): Assessment {
    return store.getters.getAssessment
  }

  get cachedNistFunctions(): NistFunction[] {
    return store.getters.getCachedNistFunctions
  }

  get teamMembersExists(): boolean {
    const localAssessment = this.assessment
    return Object.keys(localAssessment ?? {}).length > 0 && Object.keys(localAssessment.teamMembers ?? {}).length > 0
  }

  get teamMembersList(): TeamMember[] {
    return this.assessment && this.assessment.teamMembers ? this.assessment.teamMembers : []
  }
  hasAssignedQuestions(teamMember: TeamMember): boolean {
    const assignedQuestions = this.getAssignedQuestionsForTeamMember(teamMember) ?? []
    return assignedQuestions.length > 0
  }

  getAssignedQuestionsForTeamMember(teamMember: TeamMember): Array<Question> {
    return teamMember.collaboratorAssignments?.map(assignment =>
        findQuestionById(assignment.primaryQuestionId)).filter(Boolean) || []
  }

  isQuestionSubmitted(question: Question, teamMember: TeamMember): boolean {
    return teamMember.collaboratorAssignments?.some(assignment => assignment.primaryQuestionId === question.id && assignment.submitted)
  }

  teamMemberDeleteIsAvailable(teamMember: TeamMember): boolean {
    const createdDate = DateTime.fromISO(teamMember.createdDate)
    const now = DateTime.now()
    const diffInHours = now.diff(createdDate, 'hours').hours
    return diffInHours >= 2
  }

  reassignQuestion(id: number): void {
    const question = this.cachedNistFunctions
        .flatMap(nistFunction => nistFunction.questions)
        .find(question => question.id === id)
    this.$emit('toggleAssignQuestionModal', question)
  }

  getCategoryById(id: number): string {
    for (let i = 0; i < this.cachedNistFunctions.length; i++) {
      const nistFunction = this.cachedNistFunctions[i]
      for (let i1 = 0; i1 < nistFunction.nistCategories.length; i1++) {
        const category = nistFunction.nistCategories[i1]
        if (category.id == id) {
          return category.displayName
        }
      }
    }
    return ''
  }

  get questionKey(): number {
    return store.getters.getQuestionSheetKey
  }

  toggleRemoveAssignmentModal(questionId: number): void {
    this.questionIdToRemove = questionId
    this.showRemoveAssignmentModal = !this.showRemoveAssignmentModal
  }

  @Watch('showDeleteDisabledTooltip')
  onShowDeleteDisabledTooltipChanged(): void {
    if (this.showDeleteDisabledTooltip) {
      setTimeout(() => (this.showDeleteDisabledTooltip = false), 4000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';
@import '../styles/setup/_typography.scss';

.v-expansion-panel-header {
  border: $brand-shades-navy-300;
  padding: 10px;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
}

.icon {
  order: 0;
}

.header {
  order: 1;
}

.button-white {
  background-color: white !important;
  border-radius: 4px;
  border: 1px solid $brand-shades-navy-500;
  color: $brand-shades-navy-500;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  height: 24px !important;
  line-height: 130%; /* 14.3px */
  gap: 8px;
  margin: 5px !important;
}

.accept-button {
  justify-content: center;
  align-items: center;
  height: 24px !important;
  padding: 4px 16px 3px 8px !important;
  background-color: $brand-shades-navy-500 !important;
  border-radius: 4px;
  border: 1px solid $brand-shades-navy-500;
  color: $brand-shades-white;
}

.img-user {
  width: 30px;
  left: -9px;
  margin-right: -10px;
  padding-right: 0;
}

.img-chevron {
  width: 20px;
  z-index: 1;
}

.status-rectangle {
  padding: 3px 5px 2px 5px;
  align-items: center;
  gap: 6px;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 2px;
}

.in-progress {
  @extend .status-rectangle;
  border-radius: 2px;
  background: $status-warning;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.complete {
  @extend .status-rectangle;
  border: 1px solid #9BB7D4;
  color: $brand-shades-navy-500
}

.modal-header {
  background-color: $brand-shades-navy-50;
}

.icon-click-area {
  padding: 10px;
  display: inline-block;
}

.delete-icon-active {
  @extend .icon-click-area;
  cursor: pointer;
}

.delete-icon-inactive {
  @extend .icon-click-area;
  cursor: not-allowed !important;
}
</style>
