<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {AssessmentStorageService} from '@/service/AssessmentStorageService'
import {RouterUtils} from '@/utils/router-utils'
import {unassignPrimaryQuestion} from '@/utils/assign-question-utils'

@Component
export default class RemoveAssignmentModal extends Vue {
  @Prop()
  isOpen!: boolean

  @Prop()
  questionId!: number
  assessmentStorageService = new AssessmentStorageService()
  routerUtils = new RouterUtils()

  localRemoveAssignmentModal = false

  @Watch('isOpen')
  onIsOpenChange(val: boolean): void {
    this.localRemoveAssignmentModal = val
  }

  toggleRemoveAssignmentModal(): void {
    this.$emit('toggleRemoveAssignmentModal')
  }

  async removeAssignedQuestion(questionId: number): Promise<void> {
    await unassignPrimaryQuestion(questionId)
    this.toggleRemoveAssignmentModal()
  }
}
</script>

<template>
  <v-dialog
      v-model="localRemoveAssignmentModal"
      @click:outside="toggleRemoveAssignmentModal"
      data-cy="assign-category-modal"
      height="253px"
      width="777px"
  >
    <v-card>
      <v-card-title class="pa-3 modal-header">
        <v-container>
          <v-row data-cy="modal-title" justify="start" class="pb-0 mt-1 pl-3 header-sm"> REMOVE THIS ASSIGNMENT</v-row>
          <v-row class="mt-1" justify="start">
          </v-row>
        </v-container>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-container>
          <v-row>
            <v-col>
              <span class="body-lg" data-cy="remove-assignment-label"
              >Removing an assignment clears and resets the question within all views</span
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-row class="pl-7 pt-0 mt-auto mb-6">
          <v-col cols="auto">
            <v-btn
                class="button-md-neutral navy-500-background"
                data-cy="remove-button"
                dark
                @click="removeAssignedQuestion(questionId)"
            >Remove
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn class="button-md-neutral" outlined data-cy="cancel-button" @click="toggleRemoveAssignmentModal()"
            >Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

.modal-header {
  height: 117px;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  background-color: $brand-shades-navy-50;
}

.modal-content {
  margin: 16px 16px 16px 0px;
}

.assign-button {
  display: inline-flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #00274e;
  background: $brand-shades-navy-500;
}

.cancel-button {
  display: inline-flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid $brand-shades-navy-500;
}
</style>
