<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
      fill="#00274E"
    />
    <path
      d="M17.525 25.657L12.575 20.707L13.989 19.293L17.643 22.943L17.525 22.828L26.01 14.343L27.424 15.757L18.939 24.243L17.526 25.656L17.525 25.657Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class CheckMarkButtonIcon extends Vue {}
</script>
