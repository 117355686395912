<template>
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.71309 6.99999L1.70209 0.98999L0.288086 2.40399L4.88809 7.00399L0.288086 11.597L1.70209 13.011L7.71309 6.99999Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'

@Component
export default class NextCategoryIcon extends Vue {
  get iconColor(): string {
    return variables.white
  }
}
</script>
