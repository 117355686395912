import router from '@/router/router'
import store from '@/store'
import { AppUtils } from '@/utils/app-utils'
import { DateTime } from 'luxon'
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { User } from '@/models/User'
import { Vue } from 'vue-property-decorator'
import { clearErrors } from '@/utils/error-handing-utils'
import { getCurrentUser, logout } from '@/service/UserService'

export class RouterUtils extends Vue {
  clearLogin(): void {
    sessionStorage.removeItem('login')
  }

  isLogin(): string | null {
    const isLogin = sessionStorage.getItem('login')
    sessionStorage.removeItem('login')
    return isLogin
  }

  login(): void {
    sessionStorage.setItem('login', 'true')
    router.push({
      name: 'LoadingPage'
    })
  }

  logout(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    logout().then((user: User) => {
      store.commit('setCurrentUser', user)
      next()
    })
  }

  navigateToError(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    next()
  }

  navigateToPushedRoute(currentRouteName: string | null | undefined, routeName: string): void {
    const appUtils = new AppUtils()
    appUtils.loadRequiredData()

    if (currentRouteName === routeName) {
      return
    }
    clearErrors()
    router.push({
      name: routeName
    })
  }

  navigateToRoute(to: Route, from: Route, next: NavigationGuardNext<Vue>, routes: RouteConfig[]): void {
    const cyberRiskError = store.getters.getCyberRiskError
    const currentUser = store.getters.getCurrentUser

    if (cyberRiskError?.statusCode) {
      clearErrors()
      next()
    } else if (!this.hasValidUser(currentUser)) {
      getCurrentUser().then((user: User | void) => {
        if (user) {
          user.timestamp = DateTime.local().toISO({ includeOffset: false })
          store.commit('setCurrentUser', user)
          this.doNavigate(to, from, next, routes, user)
        }
      })
    } else {
      this.doNavigate(to, from, next, routes, currentUser)
    }
  }

  private cacheRoute(name: string | null | undefined) {
    if (name) {
      sessionStorage.setItem('cachedRoute', name)
    }
  }

  private clickLogin(): void {
    const el = document.getElementById('login-anchor')
    if (el) {
      el.click()
    }
  }

  private doNavigate(to: Route, from: Route, next: NavigationGuardNext<Vue>, routes: RouteConfig[], user: User): void {
    if (to.name != 'LoadingPage' && from.name != 'LoadingPage') {
      const appUtils = new AppUtils()
      appUtils.loadRequiredData(true)
    }

    const landingPage = 'LandingPage'
    const accessDenied = 'AccessDenied'
    const cachedRouteKey = 'cachedRoute'
    const cachedRouteName = (sessionStorage.getItem(cachedRouteKey) ? sessionStorage.getItem(cachedRouteKey) : '') as string
    const cachedRoute = routes.filter((route) => route.name === cachedRouteName)[0]
    const isProtected = cachedRoute ? cachedRoute.meta.isProtected : to.meta.isProtected

    if (user.authenticated && !user.authorized) {
      this.logout(to, from, next)
      sessionStorage.removeItem(cachedRouteKey)
      next(routes.filter((route) => route.name === accessDenied)[0])
    }

    if (cachedRoute && !user.authenticated) {
      sessionStorage.removeItem(cachedRouteKey)
      next(routes.filter((route) => route.name === landingPage)[0])
    } else if ((!user.collaborator && isProtected && user.authenticated) || !isProtected) {
      if (cachedRoute && to.name === landingPage) {
        sessionStorage.removeItem(cachedRouteKey)
        next(cachedRoute)
      } else {
        next()
      }
    } else if (user.collaborator) {
      if (to.meta?.collaboratorAccessible) {
        sessionStorage.removeItem(cachedRouteKey)
        next()
      } else {
        next('/app/welcome')
      }
    } else {
      this.cacheRoute(to.name)
      this.clickLogin()
    }
  }

  private hasValidUser(user: User): boolean {
    if (user?.timestamp) return DateTime.fromISO(user.timestamp) < DateTime.local().minus({ seconds: 15 })

    return false
  }
}
