<template>
  <v-col
    align="center"
    :class="'pl-0 ml-0 mr-0 ' + (endTile ? 'pr-0' : 'pr-2')"
    @click="navigateToRoute(routeName)"
    :data-cy="`risk-calculator-navbar-${nistFunctionName.toLowerCase()}`"
  >
    <v-hover v-slot="{ hover }">
      <v-sheet :class="`navbar-sheet pa-0 ma-0 ${assignActiveNavClasses(routeName)}`">
        <v-row class="ma-0 pa-0" justify="end">
          <v-col class="my-0 ml-0 mr-3 pa-0" cols="auto">
            <v-badge
              :color="progressBlue"
              :content="numBlank.toString()"
              :data-cy="`${nistFunctionName.toLowerCase()}-navigation-tile-unanswered-badge`"
              offset-y="5"
              overlap
              v-if="numBlank"
            >
            </v-badge>
            <v-badge
              :color="badge ? 'green' : 'white'"
              :data-cy="`${nistFunctionName.toLowerCase()}-navigation-tile-complete-badge`"
              offset-y="5"
              overlap
              :icon="badge ? 'mdi-check' : ''"
              v-else
            >
            </v-badge>
          </v-col>
        </v-row>
        <v-row class="mt-0" justify="center">
          <v-icon
            class="navbar-icon"
            :color="getIconColor(hover)"
            :data-cy="`risk-calculator-navbar-${nistFunctionName.toLowerCase()}-icon`"
            size="46"
            >{{ iconMapping }}</v-icon
          >
        </v-row>
        <v-row class="mt-4" justify="center">
          <span :class="`label-sm ${getTextClass(hover)}`" :data-cy="`navbar-icon-${nistFunctionName.toLowerCase()}-text`">{{
            nistFunctionName
          }}</span>
        </v-row>
        <v-row class="mt-4 pb-4" justify="center">
          <v-icon
            :class="categoryProgressClass(categoryProgress)"
            class="progress-dot pr-2"
            :key="i"
            v-for="(categoryProgress, i) in categoryProgressArray"
            >mdi-circle</v-icon
          >
        </v-row>
      </v-sheet>
    </v-hover>
  </v-col>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'
import { CategoryProgress } from '@/models/CategoryProgress'
import { FunctionProgress } from '@/models/FunctionProgress'
import { Prop, Vue } from 'vue-property-decorator'
import { RouterUtils } from '@/utils/router-utils'

@Component
export default class NavBarTile extends Vue {
  @Prop({ default: false })
  badge!: boolean

  @Prop({ default: false })
  endTile!: boolean

  @Prop()
  functionId!: number

  @Prop()
  categoryProgressArray!: CategoryProgress[]

  @Prop()
  functionProgressArray!: FunctionProgress[]

  @Prop()
  iconMapping!: string

  @Prop()
  nistFunctionName!: string

  @Prop()
  routeName!: string

  @Prop({ default: false, type: Boolean })
  active!: boolean

  progressBlue = variables.blue

  routerUtils = new RouterUtils()

  get currentRouteName(): string | null | undefined {
    return this.$route.name
  }

  get numBlank(): number {
    let result = 0
    if (this.functionProgressArray) {
      const foundFunction = this.functionProgressArray.filter((f: FunctionProgress) => f.functionId === this.functionId)[0]
      result = foundFunction ? foundFunction.totalQuestionsBlank : 0
    }

    return result
  }

  assignActiveTextColor(routeName: string): string {
    return this.isActive(routeName) ? 'label-sm' : 'label-sm-ultralight'
  }

  assignActiveNavClasses(routeName: string): string {
    return this.isActive(routeName) ? 'outlined-nav-option' : 'clickable'
  }

  navigateToRoute(routeName: string): void {
    this.routerUtils.navigateToPushedRoute(this.currentRouteName, routeName)
  }

  categoryProgressClass(categoryProgress: CategoryProgress): string {
    switch (categoryProgress.status) {
      case 'COMPLETE':
        return 'progress-dot--complete'
      case 'IN_PROGRESS':
        return 'progress-dot--in-progress'
      default:
        return ''
    }
  }

  getIconColor(hover: boolean): string {
    if (this.active) return variables.navy500

    if (hover) return variables.linkHover

    return variables.navy100
  }

  getTextClass(hover: boolean): string {
    if (this.active) return 'navbar-icon-text--active'

    if (hover) return 'navbar-icon-text--hover'

    return 'navbar-icon-text--inactive'
  }

  private isActive(routeName: string): boolean {
    return this.currentRouteName === routeName
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

.outlined-nav-option {
  border: 1px solid $brand-shades-navy-500;
  box-sizing: border-box;
  border-radius: 8px;
}

.navbar-icon {
  font-size: 46px !important;
  height: 46px !important;
  width: 46px !important;
}

.navbar-sheet {
  border-radius: 8px;
  height: 110px;
  width: 151px;
  flex-grow: 0;
  order: 0;
  flex: none;
}

.progress-dot {
  font-size: 8px;
  color: $brand-shades-navy-100;

  &--complete {
    color: $status-success;
  }

  &--in-progress {
    color: $progress-blue;
  }
}

.navbar-icon-text {
  &--active {
    color: $brand-shades-navy-500;
  }

  &--inactive {
    color: $brand-shades-navy-100;
  }

  &--hover {
    color: $status-link-hover;
  }
}
</style>
