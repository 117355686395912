import axiosInstance from '@/utils/axios-instance'
import store from '@/store'
import {AssessmentAnswer} from '@/models/AssessmentAnswer'
import {Assessment} from '@/models/Assessment'
import {CyberRiskError, ExceptionType} from '@/models/CyberRiskError'
import {DateTime} from 'luxon'
import {QuestionOption} from '@/models/QuestionOption'
import {Question} from '@/models/Question'
import {Vue} from 'vue-property-decorator'
import {getProgress} from '@/service/ScoringService'
import {handleError} from '@/utils/error-handing-utils'

export class AssessmentStorageService extends Vue {

  saveAssessmentAnswer(questionId: number, optionId: number | null, note: string): void {
    const currentAssessment: Assessment = store.getters.getAssessment

    if (!this.answerExistsOnAssessment(currentAssessment, optionId, questionId, note)) {

      const answer: AssessmentAnswer = new AssessmentAnswer()
      answer.questionId = questionId
      optionId && (answer.optionId = optionId)
      answer.note = note
      answer.lastUpdatedByUsername = store.getters.getCurrentUser.username
      answer.lastUpdatedDateTime = DateTime.local().toISO({ includeOffset: false }) ?? '';

      const updatedAssessment = this.updateAssessmentWithAnswer(currentAssessment, answer)
      store.commit('setAssessment', updatedAssessment)
      getProgress(store.getters.getAssessment).then(progress => {
        store.commit('setAssessmentProgress', progress)
      })

      store.commit('setAssessmentSaveDisabled', true)
      this.updateAssessment(updatedAssessment).then(() => {
        store.commit('setAssessmentSaveDisabled', false)
      })
    }
  }

  createAssessment(): Promise<Assessment | void> {

    let assessment: Assessment
    return axiosInstance.post('/api/assessment/create')
        .then(response => {
          assessment = response.data
          return assessment
        })
        .catch(error => {
          const cyberRiskError = new CyberRiskError()
          cyberRiskError.type = 'critical'
          cyberRiskError.statusCode = error?.response?.status

          handleError(cyberRiskError)
        })
  }

  updateAssessment(assessment: Assessment, type?: ExceptionType): Promise<Assessment | void> {
    let updatedAssessment: Assessment
    return axiosInstance.post('/api/assessment/update', assessment)
        .then(response => {
          updatedAssessment = response.data
          store.commit('setAssessment', assessment)
          return updatedAssessment
        })
        .catch(error => {
          const cyberRiskError = new CyberRiskError()
          cyberRiskError.statusCode = error?.response?.status
          cyberRiskError.type = type ? type : 'app'
          handleError(cyberRiskError)
        })
  }

  updateAssessmentWithAnswer(assessment: Assessment, answer: AssessmentAnswer): Assessment {

    if (assessment.answers == null) {
      assessment.answers = []
      assessment.answers.push(answer)
    } else {
      const foundAnswerIndex = assessment.answers.findIndex(({questionId}) => questionId === answer.questionId)
      if (foundAnswerIndex > -1) {
        assessment.answers.splice(foundAnswerIndex, 1, answer)
      } else {
        assessment.answers.push(answer)
      }
    }
    return assessment
  }

    async preloadUsersAssessment() : Promise<void> {
        const cachedAssessment = store.getters.getAssessment
        if (cachedAssessment == null || cachedAssessment.id == null) {
            const currentUser = store.getters.getCurrentUser
            store.commit('setAssessmentLoading', true)
            const assessment = currentUser.collaborator
                ? await this.findAssessmentCollaborator(currentUser.email)
                : await this.findAssessment()
            store.commit('setAssessmentLoading', false)
            const error: CyberRiskError = store.getters.getCyberRiskError
            if (assessment) {
                this.loadFoundAssessment(assessment)
            } else if (!error.type && !currentUser.collaborator) {
                this.createAssessment().then(assessment => {
                    if (assessment) {
                        this.loadFoundAssessment(assessment)
                    }
                })
            }
        }
    }

  findAssessment(): Promise<Assessment | void> {

    let assessment: Assessment
    return axiosInstance.get('/api/assessment')
        .then(response => {
          assessment = response.data
          return assessment
        })
        .catch(error => {
          const cyberRiskError = new CyberRiskError()
          cyberRiskError.type = 'critical'
          cyberRiskError.statusCode = error?.response?.status

          handleError(cyberRiskError)
        })
  }

  async findAssessmentCollaborator(email: string): Promise<Assessment | void> {

    let assessment: Assessment
    try {
      const response = await axiosInstance.get('/api/assessmentCollaborator',{params: {email: email}});
      assessment = response.data
      return assessment
    } catch (error) {
      const cyberRiskError = new CyberRiskError()
      cyberRiskError.type = 'critical'
      cyberRiskError.statusCode = error?.response?.status

      handleError(cyberRiskError)
    }
  }

  findExistingAnswerOnAssessment(assessment: Assessment, options: QuestionOption[]): QuestionOption | null {

    if (assessment != null && assessment.answers != null) {
      for (const i in options) {
        const foundOptionIndex = assessment.answers.findIndex(({optionId}) => optionId === options[i].id)
        if (foundOptionIndex > -1) {
          return options[i]
        }
      }
    }

    return null
  }

  answerExistsOnAssessment(assessment: Assessment, optionIdToFind: number | null, questionIdToFind: number, noteToFind: string): boolean {

    let exists = false
    if (assessment != null && assessment.answers != null) {
      const found = assessment.answers.filter(answer => answer.optionId == optionIdToFind && (answer.questionId == questionIdToFind && answer.note == noteToFind))
      exists = found.length > 0
    }
    return exists
  }

  removeAnswersFromAssessmentForQuestions(assessment: Assessment, questions: Question[]): void {

    if (assessment != null && assessment.answers != null) {
      for (const i in questions) {
        const foundOptionIndex = assessment.answers.findIndex(({questionId}) => questionId === questions[i].id)
        if (foundOptionIndex > -1) {
          assessment.answers.splice(foundOptionIndex, 1)
          store.commit('setAssessment', assessment)
        }
      }
    }
  }

  loadFoundAssessment(assessment: Assessment): void {
    store.commit('setAssessment', assessment)
    getProgress(store.getters.getAssessment).then(progress => {
      store.commit('setAssessmentProgress', progress)
    })
  }
}
