<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class EmptyState extends Vue {
  @Prop()
  subtext1!: string

  @Prop()
  subtext2!: string

  @Prop()
  identifierForFolderLogo!: string

  @Prop()
  identifierForFirstLine!: string

  @Prop()
  identifierForSecondLine!: string

  @Prop()
  sourceImage!: string
}
</script>

<template>
  <v-row align="center" class="px-1 py-0" id="empty-state">
    <v-container>
      <v-divider align="stretch" />
      <v-row align="stretch" class="mt-0" :data-cy="identifierForFolderLogo">
        <v-col cols="12" class="d-flex justify-center">
          <v-img
            class="shrink mr-2"
            :data-cy="'source_' + sourceImage"
            contain
            :src="require('@/assets/icons/' + sourceImage + '.svg')"
            width="75px"
          />
        </v-col>
      </v-row>
      <v-row align="stretch" class="mt-0">
        <v-col class="pa-0 ma-0 d-flex justify-center" cols="12">
          <div class="body-md body-sm-semibold text-align-center" :data-cy="identifierForFirstLine">
            {{ subtext1 }}
          </div>
        </v-col>
      </v-row>
      <v-row align="stretch">
        <v-col class="pt-1 d-flex justify-center" cols="12">
          <div class="body-sm-light text-align-center" :data-cy="identifierForSecondLine">{{ subtext2 }}</div>
        </v-col>
      </v-row>
      <v-divider align="stretch" class="mt-7" />
    </v-container>
    <v-divider />
  </v-row>
</template>

<style scoped lang="scss"></style>
