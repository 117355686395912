<template>
  <svg v-if="!hasPrevious" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
      fill="#E6E9ED"
    />
    <path
      d="M19.585 26.01L13.575 20L19.585 13.99L21 15.404L16.4 20.004L21 24.604L19.586 26.01H19.585ZM25.01 26.01L18.999 20L25.01 13.99L26.424 15.404L21.824 20.004L26.424 24.604L25.011 26.01H25.01Z"
      fill="#99A9B8"
    />
  </svg>
  <svg v-else width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
      fill="#F2F4F6"
    />
    <path
      d="M19.585 26.01L13.575 20L19.585 13.99L21 15.404L16.4 20.004L21 24.604L19.586 26.01H19.585ZM25.01 26.01L18.999 20L25.01 13.99L26.424 15.404L21.824 20.004L26.424 24.604L25.011 26.01H25.01Z"
      fill="#00274E"
    />
    <path
      d="M4 2H36V-2H4V2ZM38 4V36H42V4H38ZM36 38H4V42H36V38ZM2 36V4H-2V36H2ZM4 38C2.89543 38 2 37.1046 2 36H-2C-2 39.3137 0.686293 42 4 42V38ZM38 36C38 37.1046 37.1046 38 36 38V42C39.3137 42 42 39.3137 42 36H38ZM36 2C37.1046 2 38 2.89543 38 4H42C42 0.686291 39.3137 -2 36 -2V2ZM4 -2C0.686291 -2 -2 0.686293 -2 4H2C2 2.89543 2.89543 2 4 2V-2Z"
      fill="#CCD4DC"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PreviousFunctionNavIcon extends Vue {
  get hasPrevious(): boolean {
    const identify = 'RoadmapIdentify'
    const protect = 'RoadmapProtect'
    const detect = 'RoadmapDetect'
    const respond = 'RoadmapRespond'
    const recover = 'RoadmapRecover'

    const currentRoute = this.$router.currentRoute
    const routeNames = [identify, protect, detect, respond, recover]
    let hasPrevious = false
    for (let i = 0; i < routeNames.length; i++) {
      const routeName = routeNames[i]
      if (routeName === currentRoute.name) {
        hasPrevious = !!routeNames[i - 1]
        break
      }
    }
    return hasPrevious
  }
}
</script>
