<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.315 14.4342C1.10461 14.4339 0.904046 14.3452 0.762245 14.1897C0.617829 14.0356 0.546068 13.8271 0.564995 13.6167L0.748745 11.5962L9.23725 3.11074L11.89 5.76274L3.40375 14.2475L1.38325 14.4312C1.36 14.4335 1.33675 14.4342 1.315 14.4342ZM12.4195 5.23249L9.7675 2.58049L11.3582 0.98974C11.4989 0.848908 11.6898 0.769775 11.8889 0.769775C12.0879 0.769775 12.2788 0.848908 12.4195 0.98974L14.0102 2.58049C14.1511 2.72117 14.2302 2.91206 14.2302 3.11112C14.2302 3.31017 14.1511 3.50106 14.0102 3.64174L12.4202 5.23174L12.4195 5.23249Z"
      fill="#00274E"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PencilIcon extends Vue {}
</script>
