<template>
  <v-dialog
    @click:outside="closeDialog"
    :data-cy="`preview-modal-${productType}`"
    height="900"
    width="695.17"
    v-model="showDialog"
  >
    <v-row align="center" justify="center" no-gutters data-cy="image-row">
      <v-card v-if="previewImage">
        <v-img contain :src="require('@/assets/product-offering/' + previewImage)" />
      </v-card>
    </v-row>
  </v-dialog>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class PreviewModal extends Vue {
  @Prop()
  isOpen!: boolean

  @Prop()
  previewImage!: string

  @Prop()
  productType!: string

  showDialog = false

  closeDialog(): void {
    this.$emit('togglePreview', this.productType)
  }

  @Watch('isOpen')
  onPropertyValueChanged(val: boolean): void {
    this.showDialog = val
  }
}
</script>
