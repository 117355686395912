import store from '@/store'
import { Question } from '@/models/Question'
import { NistFunction } from '@/models/NistFunction'

function findQuestionById(id: number): Question {
  const cachedNistFunctions = store.getters.getCachedNistFunctions as NistFunction[]
  let question = new Question()
  outerLoop: for (const nistFunction of cachedNistFunctions) {
    for (const q of nistFunction.questions) {
      if (q.id === id) {
        question = q
        break outerLoop
      }
    }
  }
  return question
}

export { findQuestionById }
