<template>
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 12H5L4 10H12L11 12ZM13 7H3L2 5H14L13 7ZM15 2H1L0 0H16L15 2Z" fill="#00274E" />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class FilterIcon extends Vue {}
</script>
