<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col align="center" cols="auto">
        <v-sheet
          class="pa-0 clickable"
          @click="navToPage"
          :color="tileColor"
          data-cy="nist-score-sheet"
          :height="hasSmallTileSize ? '100px' : '120px'"
          :width="hasSmallTileSize ? '100px' : '120px'"
          rounded
        >
          <v-row justify="center" align="center">
            <v-col :class="hasSmallTileSize ? 'pt-2 ' : ''" cols="auto">
              <div :class="'header-xxl-neutral ' + scoreTextColor" data-cy="product-offering-tile-nist-maturity-score">
                {{ nistMaturityScore }}
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="py-0" cols="auto">
        <div class="label-md" data-cy="product-offering-tile-score-text">YOUR SCORE</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import store from '@/store'
import variables from '@/styles/_export.module.scss'
import { Prop, Vue } from 'vue-property-decorator'
import { UserPreferences } from '@/models/UserPreferences'

@Component
export default class ProductOfferingNistScore extends Vue {
  @Prop()
  hasSmallTileSize!: boolean

  @Prop()
  getStartedRoute!: string

  get userPreferences(): UserPreferences {
    return store.getters.getUserPreferences
  }

  tileColor = ''
  scoreTextColor = ''

  get nistMaturityScore(): number {
    return this.userPreferences ? this.userPreferences.nistMaturityScore : 0.0
  }

  navToPage(): void {
    this.$router.push(this.getStartedRoute)
  }

  mounted(): void {
    const score = this.nistMaturityScore
    if (!score || score < 0.5) {
      this.tileColor = variables.red700
      this.scoreTextColor = 'text-white'
    } else if (score > 0.49 && score < 1.0) {
      this.tileColor = variables.red600
      this.scoreTextColor = 'text-white'
    } else if (score > 0.99 && score < 2.0) {
      this.tileColor = variables.orange
      this.scoreTextColor = 'text-brown'
    } else if (score > 1.9 && score < 2.5) {
      this.tileColor = variables.gold300
      this.scoreTextColor = 'text-brown'
    } else if (score > 2.49 && score < 3.0) {
      this.tileColor = variables.shamrock300
      this.scoreTextColor = 'text-green'
    } else if (score > 2.99 && score < 4.0) {
      this.tileColor = variables.shamrock500
      this.scoreTextColor = 'text-white'
    } else {
      this.tileColor = variables.shamrock700
      this.scoreTextColor = 'text-white'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

.text-brown {
  color: $risk-text-brown;
}

.text-blue {
  color: $brand-shades-navy-500;
}

.text-green {
  color: $risk-text-green;
}

.text-white {
  color: $brand-shades-white;
}
</style>
