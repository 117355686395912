<template>
  <v-app
    class="background"
    v-on:toggleNoTeamMembersModal="toggleNoTeamMembersModal"
    v-on:toggleAssignQuestionModal="toggleAssignQuestionModal"
    v-on:toggleAssignCategoryModal="toggleAssignCategoryModal"
  >
    <v-app-bar v-if="!isMobile" app color="background" data-cy="header" dark elevation="0" height="100px">
      <v-row class="pa-0 mt-n5" justify="space-between">
        <v-col class="mt-5" cols="4">
          <v-row justify="start">
            <v-col cols="auto">
              <v-img
                alt="GAIG Logo"
                class="shrink mr-2 clickable"
                contain
                data-cy="company-header-logo"
                src="./assets/eagle-eye-logo.svg"
                transition="scale-transition"
                width="200px"
                @click="handleNavClick()"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="mt-8" cols="4">
          <v-row justify="end">
            <v-col class="ma-2" cols="auto" data-cy="app-bar-tools" v-if="showTools" id="app-bar-tools">
              <v-row>
                <v-col class="pa-1" cols="auto">
                  <v-hover>
                    <v-icon class="clickable" @click="toolMenu = !toolMenu" data-cy="app-bar-tools-chart-icon" size="18"
                      >$vuetify.icons.barChart
                    </v-icon>
                  </v-hover>
                </v-col>
                <v-col class="pa-1" cols="auto">
                  <v-hover>
                    <span class="clickable label-sm" @click="toolMenu = !toolMenu" data-cy="appbar-tools-label">Tools</span>
                  </v-hover>
                </v-col>
                <v-col class="pa-1" cols="auto">
                  <v-menu nudge-bottom="20" v-model="toolMenu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark icon v-bind="attrs" v-on="on" x-small>
                        <v-icon
                          :class="toolMenu ? 'caret-icon-active' : 'caret-icon'"
                          data-cy="app-bar-tools-caret-icon"
                          size="10"
                          >$vuetify.icons.caretDown
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item :key="i" v-for="(tool, i) in tools">
                        <v-btn
                          class="body-sm navy-500-btn-txt"
                          :data-cy="'app-bar-tools-menu-item-' + tool.id"
                          text
                          :to="tool.route"
                          >{{ tool.title }}
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="clickable ma-2"
              @click="userMenu = !userMenu"
              cols="auto"
              data-cy="app-bar-user"
              v-if="showUserMenu"
            >
              <v-row>
                <v-col class="pa-1" cols="auto">
                  <v-hover>
                    <v-icon data-cy="app-bar-user-user-icon" size="18">$vuetify.icons.user </v-icon>
                  </v-hover>
                </v-col>
                <v-col class="pa-1" cols="auto">
                  <v-hover>
                    <span class="clickable label-sm" data-cy="app-bar-user-username">{{ userName }}</span>
                  </v-hover>
                </v-col>
                <v-col class="pa-1" cols="auto">
                  <v-menu nudge-bottom="20" v-model="userMenu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark icon v-bind="attrs" v-on="on" x-small>
                        <v-icon
                          :class="userMenu ? 'caret-icon-active' : 'caret-icon'"
                          data-cy="app-bar-user-caret-icon"
                          size="10"
                          >$vuetify.icons.caretDown
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-btn class="body-sm navy-500-btn-txt" data-cy="app-bar-user-logout" @click="logout" text
                          >Log out
                        </v-btn>
                      </v-list-item>
                      <v-list-item v-if="!currentUser.collaborator || !assessment">
                        <v-btn class="body-sm navy-500-btn-txt" data-cy="app-bar-manage-teams" @click="manageTeams" text
                          >Manage teams
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="mr-3" cols="auto" v-if="showCreateAccount">
              <v-btn
                class="navy-500-btn-txt"
                data-cy="create-account-button"
                :href="registrationURL"
                plain
                :ripple="false"
                small
                target="_blank"
                text
              >
                <span class="text-link-button">Create an Account</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="showLogin">
              <v-btn class="navy-500-btn-txt" @click="doLogin" data-cy="login-button" plain :ripple="false" small text>
                <span class="text-link-button">Log In</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-dialog data-cy="session-timeout-modal" height="285px" persistent v-model="showTimeOutModal" width="450px">
      <v-card class="pa-8" rounded>
        <v-row justify="start">
          <v-col cols="auto">
            <div class="header-sm" data-cy="session-timeout-modal-header">Warning: Session is about to expire</div>
          </v-col>
        </v-row>
        <v-row align="center" justify="start">
          <v-col cols="auto" class="pr-1">
            <div class="desktop" data-cy="session-timeout-modal-subtext">Your session will expire in</div>
          </v-col>
          <v-col cols="auto" class="pl-1">
            <div :key="expirationDurationKey" class="desktop-alert" data-cy="session-timeout-modal-time-remaining">
              {{ sessionTimeoutDurationMessage }}
            </div>
          </v-col>
        </v-row>
        <v-row justify="start">
          <v-col cols="auto">
            <div class="desktop" data-cy="session-timeout-modal-continue-instruction">
              Click Continue to remain logged in.
            </div>
          </v-col>
        </v-row>
        <v-row justify="start">
          <v-col cols="auto">
            <v-btn
              class="button-md-neutral navy-500-background"
              data-cy="session-timeout-modal-continue-button"
              dark
              @click="resetTimeout"
              >Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <no-team-members-modal :isOpen="showNoTeamMembersModal" v-on:toggleNoTeamMembersModal="toggleNoTeamMembersModal" />
    <assign-question-modal
      :isOpen="showAssignQuestionModal"
      :question="selectedQuestion"
      :teamMemberList="teamMembersList"
      v-on:toggleAssignQuestionModal="toggleAssignQuestionModal"
    />
    <assign-category-modal
      :isOpen="showAssignCategoryModal"
      :teamMemberList="teamMembersList"
      :selectedCategory="selectedCategory"
    />
    <v-main class="mb-4">
      <v-row justify="center">
        <v-col cols="auto">
          <TryAgainErrorAlert test-id="app-error" v-model="showError" width="650px" />
        </v-col>
      </v-row>
      <v-container class="pr-0 pl-0 pt-0 mt-n4 main-content-container" fluid>
        <a href="/login" hidden id="login-anchor" />
        <router-view v-on:toggleAssignQuestionModal="toggleAssignQuestionModal" />
      </v-container>
    </v-main>
    <app-footer id="footer" :is-authenticated="isAuthenticated" :is-mobile="isMobile" />
  </v-app>
</template>

<script lang="ts">
import AppFooter from '@/components/AppFooter.vue'
import Component from 'vue-class-component'
import store from '@/store'
import { AppConfig } from '@/models/AppConfig'
import { AppUtils } from '@/utils/app-utils'
import { Assessment } from '@/models/Assessment'
import { RouterUtils } from '@/utils/router-utils'
import { User } from '@/models/User'
import { Vue, Watch } from 'vue-property-decorator'
import { getAppConfig } from '@/service/ConfigService'
import { getPersonalChecklist } from '@/service/PDFService'
import { getCurrentUser, logout } from '@/service/UserService'
import NoTeamMembersModal from '@/components/NoTeamMembersModal.vue'
import AssignQuestionModal from '@/components/AssignQuestionModal.vue'
import { Question } from '@/models/Question'
import { TeamMember } from '@/models/TeamMember'
import AssignCategoryModal from '@/components/AssignCategoryModal.vue'
import { NistCategory } from '@/models/NistCategory'
import TryAgainErrorAlert from '@/components/TryAgainErrorAlert.vue'
import {NistFunction} from "@/models/NistFunction"

@Component({
  components: {
    TryAgainErrorAlert,
    AssignCategoryModal,
    AssignQuestionModal,
    NoTeamMembersModal,
    AppFooter
  }
})
export default class App extends Vue {
  // eslint-disable-next-line
  appUtils = new AppUtils()
  countdownTimer!: number | undefined
  expirationDurationKey = 0
  isTimeoutOpen = false
  logoutURL!: string
  registrationURL = ''
  routerUtils = new RouterUtils()
  sessionExpirationMinutes = 1
  sessionExpirationSeconds = 59
  showNoTeamMembersModal = false
  showAssignQuestionModal = false
  showAssignCategoryModal = false
  selectedQuestion = new Question()
  selectedCategory: NistCategory | null = null
  showError = false
  timeoutDuration = 120
  toolMenu = false
  userMenu = false

  @Watch('hasAppError')
  onHasErrorChanged(val: boolean): void {
    this.showError = val
  }

  @Watch('timeoutDuration')
  onDurationChanged(val: number): void {
    if (val < 1) {
      this.logout()
      clearInterval(this.countdownTimer)
      this.isTimeoutOpen = false
      window.location.href = this.logoutURL
    }
  }

  get assessment(): Assessment {
    return store.getters.getAssessment
  }

  get assessmentRoute(): string {
    let route = ''
    if (this.assessment && this.assessment.answers) {
      route = this.assessment.answers.length > 0 ? 'RoadmapOverview' : 'LoadingPage'
    } else {
      route = 'LoadingPage'
    }

    return route
  }

  get cachedNistFunctions(): Array<NistFunction> {
    return store.getters.getCachedNistFunctions
  }

  get currentUser(): User {
    return store.getters.getCurrentUser
  }

  get hasAppError(): boolean {
    return store.getters.getCyberRiskError !== null && store.getters.getCyberRiskError.type === 'app'
  }

  get isAuthenticated(): boolean {
    return this.currentUser?.authenticated
  }

  get isAuthorized(): boolean {
    return this.currentUser?.authorized
  }

  get isAuthorizedRoute(): boolean {
    return this.$route.name !== 'AccessDenied' && this.$route.name !== 'ErrorPage'
  }

  get isMobile(): boolean {
    const breakpoint = this.$vuetify.breakpoint
    return breakpoint.width < 767
  }

  get userName(): string {
    return this.currentUser ? this.currentUser.firstName + ' ' + this.currentUser.lastName : ''
  }

  get sessionTimeoutDurationMessage(): string {
    let minuteText = this.sessionExpirationMinutes ? this.sessionExpirationMinutes + ' minute' : ''
    let secondText = this.sessionExpirationSeconds > 1 || this.sessionExpirationSeconds == 0 ? ' seconds' : ' second'
    return minuteText + ' ' + this.sessionExpirationSeconds + secondText
  }

  get showLogin(): boolean {
    const showLogin = !this.isAuthenticated && this.isAuthorizedRoute
    return showLogin
  }

  get showCreateAccount(): boolean {
    const showCreateAccount = !this.isAuthenticated && this.isAuthorizedRoute
    return showCreateAccount
  }

  /*
    Session Idle timeout set to 28min
    Timeout modal appears with 2min remaining (this.$store.state.idleVue.isIdle)
    OAuth timeout is 30min
  */
  get showTimeOutModal(): boolean {
    if (this.isTimeoutOpen) {
      return true
    } else if (this.$store.state.idleVue?.isIdle && this.currentUser.authenticated) {
      this.startTimer()
      this.isTimeoutOpen = true
      return this.$store.state.idleVue?.isIdle
    }
    return false
  }

  get showTools(): boolean {
    return this.isAuthenticated && this.isAuthorized && this.isAuthorizedRoute && !this.currentUser.collaborator
  }

  get showUserMenu(): boolean {
    return this.isAuthenticated && this.isAuthorized && this.isAuthorizedRoute
  }

  startTimer(): void {
    this.countdownTimer = setInterval(() => {
      if (this.timeoutDuration > 0) {
        --this.timeoutDuration
        this.sessionExpirationMinutes = Math.floor(this.timeoutDuration / 60)
        this.sessionExpirationSeconds = Math.floor(this.timeoutDuration % 60)
        ++this.expirationDurationKey
      }
    }, 1000)
  }

  get tools(): { id: string; route: { name: string }; title: string }[] {
    return [
      { id: 'insights', route: { name: 'EmployeeInsights' }, title: 'Employee Insights' },
      { id: 'scan', route: { name: 'Scorecard' }, title: 'Security Scorecard' },
      { id: 'assessment', route: { name: this.assessmentRoute }, title: 'Security Roadmap' }
    ]
  }

  doLogin(): void {
    this.routerUtils.login()
  }

  handleNavClick(): void {
    let routeName = 'LandingPage'
    if (this.currentUser.authorized && this.currentUser.authenticated) {
      routeName = 'Welcome'
    }

    this.routerUtils.navigateToPushedRoute(this.$route.name, routeName)
  }

  logout(): void {
    logout().then((user: User) => {
      store.commit('setCurrentUser', user)
      this.routerUtils.navigateToPushedRoute(this.$route.name, 'LandingPage')
    })
  }

  manageTeams(): void {
    this.routerUtils.navigateToPushedRoute(this.$route.name, 'ManageTeams')
  }

  toggleNoTeamMembersModal(): void {
    this.showNoTeamMembersModal = !this.showNoTeamMembersModal
  }

  get teamMembersList(): TeamMember[] {
    return this.assessment && this.assessment.teamMembers ? this.assessment.teamMembers : []
  }

  toggleAssignQuestionModal(question: Question): void {
    if (question) {
      this.selectedQuestion = question
    }

    this.showAssignQuestionModal = !this.showAssignQuestionModal
    store.commit('setQuestionSheetKey', store.getters.getQuestionSheetKey + 1)
  }

  toggleAssignCategoryModal(): void {
    this.cachedNistFunctions.forEach((nistFunction) => {
      nistFunction.nistCategories.forEach((cat) => {
        if (cat.id == store.getters.getCurrentCategoryId) {
          this.selectedCategory = cat
        }
      })
    })
    this.showAssignCategoryModal = !this.showAssignCategoryModal
  }

  resetTimeout(): void {
    getCurrentUser().then((response) => store.commit('setCurrentUser', response))
    this.isTimeoutOpen = false
    this.timeoutDuration = 120
    this.sessionExpirationMinutes = 1
    this.sessionExpirationSeconds = 59
    clearInterval(this.countdownTimer)
  }

  mounted(): void {
    getAppConfig().then((response: AppConfig) => {
      this.registrationURL = response.registrationURL
      this.logoutURL = response.logoutURL
      store.commit('setAppConfig', response)
    })

    getPersonalChecklist()
      .then((response) => {
        const file = new Blob([response], { type: 'application/pdf' })
        const url = URL.createObjectURL(file)
        store.commit('setPersonalChecklistUrl', url)
      })
      .catch((error) => {
        console.log('error loading personal checklist url on app mount', error)
      })

    this.appUtils.loadRequiredData()
  }
}
</script>

<style lang="scss" scoped>
@import './styles/_variables.scss';

.caret-icon {
  transition: transform 0.3s ease;
}

.caret-icon-active {
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.error-banner__link {
  color: $brand-shades-white;
}
</style>
