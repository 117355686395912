<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="pa-4 text-center">
      <v-dialog
          v-model="showDialog"
          height="385px"
          width="850px"
          @click:outside="closeDialog"
          data-cy="add-team-member-modal"
      >
        <v-card>
          <v-sheet class="pa-6" data-cy="add-team-member-modal-sheet" width="850px">
            <v-row class="justify-center align-center">
              <v-overlay v-if="isLoading" data-cy="overlay-is-loading" class="loading-overlay">
                <v-progress-circular indeterminate size="64" :color="loadingColor"></v-progress-circular>
              </v-overlay>
            </v-row>
            <v-row id="add-team-member-modal-title" align="stretch" class="mt-3">
              <v-col cols="11">
                <div class="header-sm"
                     data-cy="add-team-member-modal-title">
                  Add Team Member
                </div>
              </v-col>

              <v-spacer></v-spacer>
              <v-col>
                <v-icon @click="closeDialog">X</v-icon>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pt-1 d-flex" cols="9">
                <div
                    class="body-sm text-align-center"
                    id="add-team-member-modal-title-subtext-id"
                    data-cy="add-team-member-modal-title-subtext"
                >
                  Invite New Team Member/Collaborator
                </div>
              </v-col>
            </v-row>
            <v-row v-if="showErrorAlert" class="justify-center">
              <TryAgainErrorAlert test-id="add-team-member-modal" v-model="showErrorAlert" width="80%"/>
            </v-row>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field
                      label="First Name *"
                      required
                      type="input"
                      data-cy="first-name"
                      v-model="firstName"
                      :class="showFirstNameMessage ? 'error-message-margin error-message-text' : ''"
                  ></v-text-field>
                  <div data-cy="first-name-message" class="error-message-container" v-if="showFirstNameMessage">
                    <img data-cy="error-info-img" alt="" src="../assets/icons/error-info.svg"/>
                    <span
                        class="body-sm-regular error-message-text nudge-padding"> Please enter a valid First Name </span>
                  </div>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field
                      label="Last Name *"
                      data-cy="last-name"
                      type="input"
                      v-model="lastName"
                      required
                      :class="showLastNameMessage ? 'error-message-margin error-message-text' : ''"
                  ></v-text-field>
                  <div data-cy="last-name-message" class="error-message-container" v-if="showLastNameMessage">
                    <img data-cy="error-info-img" alt="" src="../assets/icons/error-info.svg"/>
                    <span
                        class="body-sm-regular error-message-text nudge-padding"> Please enter a valid Last Name </span>
                  </div>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field
                      label="Email *"
                      data-cy="email"
                      type="email"
                      v-model="email"
                      required
                      :class="showEmailMessage ? 'error-message-margin error-message-text' : ''"
                  ></v-text-field>
                  <div data-cy="email-message" class="error-message-container" v-if="showEmailMessage">
                    <img data-cy="error-info-img" alt="" src="../assets/icons/error-info.svg"/>
                    <span class="body-sm-regular error-message-text nudge-padding">
                      {{ emailErrorMessage }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row class="mb-4">
                <v-col cols="auto">
                  <v-btn
                      class="button-md-neutral navy-500-background"
                      id="add-plus-another-new-button"
                      dark
                      data-cy="add-plus-another-new-button"
                      @click="addTeamMember(false)"
                  >Add + Another New
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                      class="button-md-neutral navy-500-background"
                      id="add-plus-done-button"
                      dark
                      data-cy="add-plus-done-button"
                      @click="addTeamMember(true)"
                  >Add + Done
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                      class="button-md-neutral navy-500-background"
                      id="cancel-button"
                      dark
                      data-cy="cancel-button"
                      @click="closeDialog"
                  >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-sheet>
        </v-card>
      </v-dialog>
    </div>
  </v-form>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import {Prop, Vue, Watch} from 'vue-property-decorator'
import store from '@/store'
import {AssessmentStorageService} from '@/service/AssessmentStorageService'
import {Assessment} from '@/models/Assessment'
import {addTeamMemberToIDV} from '@/service/TeamMemberService'
import addrs from 'email-addresses'
import variables from '@/styles/_export.module.scss'
import TryAgainErrorAlert from '@/components/TryAgainErrorAlert.vue'

@Component({
  components: {TryAgainErrorAlert}
})
export default class AddTeamMemberModal extends Vue {
  @Prop()
  isOpen!: boolean

  showDialog = false

  valid = true

  firstName = ''

  lastName = ''

  email = ''

  assessmentStorageService = new AssessmentStorageService()

  showErrorAlert = false
  showEmailMessage = false
  showFirstNameMessage = false
  showLastNameMessage = false
  emailInvalidMessage = 'Please Enter Valid Email Address'
  emailErrorMessage = ''
  isLoading = false
  loadingColor = variables.navy500

  get assessment(): Assessment {
    return store.getters.getAssessment
  }

  closeDialog(): void {
    this.resetAllFields()
    this.$emit('toggleAddTeamMemberModal')
  }

  @Watch('isOpen')
  onPropertyValueChanged(val: boolean): void {
    this.showDialog = val
  }

  addTeamMember(closeOnComplete: boolean): void {
    this.showFirstNameMessage = this.firstName.trim().length === 0
    this.showLastNameMessage = this.lastName.trim().length === 0
    this.showEmailMessage = false

    if (!this.isValidEmail()) {
      this.emailErrorMessage = this.emailInvalidMessage
      this.showEmailMessage = true
      return
    }

    if (this.showFirstNameMessage || this.showLastNameMessage) {
      return
    }

    this.isLoading = true

    addTeamMemberToIDV(this.email, this.firstName, this.lastName)
        .then(() => {
          this.resetAllFields()
        })
        .catch(error => {
          const status = error?.response?.status
          if (status === 409) {
            this.showEmailMessage = true
            this.emailErrorMessage = error.response.data.errorMessage
          } else {
            this.showErrorAlert = true
          }
        })
        .finally(() => {
          this.isLoading = false
          if (!this.hasErrors) {
            if (closeOnComplete) {
              this.closeDialog()
            } else {
              this.resetAllFields()
            }
          }
        })
  }

  get hasErrors(): boolean {
    return this.showErrorAlert || this.showEmailMessage || this.showLastNameMessage || this.showFirstNameMessage
  }

  resetAllFields(): void {
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.showFirstNameMessage = false
    this.showLastNameMessage = false
    this.showEmailMessage = false
    this.showErrorAlert = false
  }

  isValidEmail(): boolean {
    return (
        addrs.parseOneAddress({
          input: this.email,
          rejectTLD: true
        }) !== null
    )
  }

  mounted(): void {
    this.assessmentStorageService.preloadUsersAssessment()
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

.error-message-margin {
  margin-bottom: -19px;
}

.nudge-padding {
  padding-top: 0.0625rem;
}

.error-message-text,
.error-message-text input {
  color: $scorecard-grade-f !important;
}

.error-message-container {
  display: flex;
  gap: 4px;
  align-items: center;
}

.loading-overlay {
  z-index: 999 !important;
}
</style>
