<template>
  <v-dialog
    v-model="localShowCategoryModalDialog"
    @click:outside="toggleAssignCategoryModal"
    data-cy="assign-category-modal"
    height="269px"
    width="475px"
  >
    <v-card>
      <v-card-title class="pa-3 modal-header">
        <v-row data-cy="modal-title" justify="start" class="pb-0 mt-1 pl-3 header-sm"> ASSIGN CATEGORY </v-row>
        <v-row class="mt-1" justify="start">
          <v-col cols="auto">
            <div class="body-sm-regular" data-cy="modal-header-subtext">
              All questions from this category will be assigned to the selected collaborator
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-container>
          <v-row>
            <v-col cols="6">
              <span class="label-md-light" data-cy="category-select-label">CATEGORY</span>
              <div class="body-sm-regular" data-cy="selectedCategory">
                {{ selectedCategory ? selectedCategory.displayName : '' }}
              </div>
            </v-col>
            <v-col cols="6">
              <span class="label-md-light" data-cy="collaborator-label">COLLABORATOR</span>
              <v-select
                :item-text="(item) => item.firstName + ' ' + item.lastName"
                :items="teamMemberList"
                class="collaborator-select"
                data-cy="collaborator-select"
                dense
                flat
                hide-details
                return-object
                single-line
                solo
                v-model="selectedTeamMember"
              >
                <template v-slot:label>
                  <label class="button-md" data-cy="collaborator-select-label">Select Collaborator</label>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-row class="pl-7 pt-0 mt-auto mb-6">
          <v-col cols="auto">
            <v-btn
              class="button-md-neutral navy-500-background"
              data-cy="assign-button"
              dark
              v-on:click="assignCategoryToTeamMember"
              >Assign
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn class="button-md-neutral" outlined data-cy="cancel-button" @click="toggleAssignCategoryModal"
              >Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Prop, Vue, Watch } from 'vue-property-decorator'
import { TeamMember } from '@/models/TeamMember'
import Component from 'vue-class-component'
import { NistCategory } from '@/models/NistCategory'
import { NistFunction } from '@/models/NistFunction'
import store from '@/store'
import { AssessmentStorageService } from '@/service/AssessmentStorageService'
import { assignQuestions } from '@/utils/assign-question-utils'
import {Assessment} from '@/models/Assessment'

@Component
export default class AssignCategoryModal extends Vue {
  @Prop()
  isOpen!: boolean

  @Prop()
  teamMemberList!: TeamMember[]

  @Prop()
  selectedCategory!: NistCategory

  assessmentStorageService = new AssessmentStorageService()

  localShowCategoryModalDialog = false
  selectedTeamMember: TeamMember | null = null

  @Watch('isOpen')
  onIsOpenChange(val: boolean): void {
    this.localShowCategoryModalDialog = val
  }

  get cachedNistFunctions(): NistFunction[] {
    return store.getters.getCachedNistFunctions
  }

  get assessment(): Assessment {
    return store.getters.getAssessment
  }

  toggleAssignCategoryModal(): void {
    this.selectedTeamMember = null
    this.$parent?.$emit('toggleAssignCategoryModal')
  }

  async assignCategoryToTeamMember(): Promise<void> {
    if (this.selectedCategory && this.selectedTeamMember) {
      let nistFunction = this.cachedNistFunctions?.find((f) => f.id === this.selectedCategory.nistFunctionId)
      let categoryQuestions = nistFunction?.questions.filter((q) => (q.categoryId === this.selectedCategory.id) && !q.primaryQuestionId)
      if (categoryQuestions) {
        await assignQuestions(this.selectedTeamMember, categoryQuestions)
      }
    }
    this.toggleAssignCategoryModal()
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

.modal-header {
  height: 117px;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  background-color: $brand-shades-navy-50;
}

.modal-content {
  margin: 16px 16px 16px 0px;
}

.collaborator-select {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 2px solid $brand-shades-navy-100;
}

.assign-button {
  display: inline-flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #00274e;
  background: $brand-shades-navy-500;
}

.cancel-button {
  display: inline-flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid $brand-shades-navy-500;
}
</style>
