<template>
  <v-dialog
      v-model="localShowAssignModalDialog"
      @click:outside="toggleAssignQuestionModal"
      height="700px"
      width="456px"
      data-cy="assign-question-modal"
  >
    <v-card data-cy="assign-question-modal-card">
      <v-card-title class="pa-3 modal-header">
        <v-row data-cy="assign-question-modal-header" justify="start" class="pb-0 mt-1 pl-3 header-sm">
          ASSIGN QUESTION
        </v-row>
        <v-row class="mt-1" justify="start">
          <v-col cols="auto">
            <div class="body-sm-regular" data-cy="assign-question-modal-header-subtext">
              Assigning individual questions within a category will remove the “Assign Category” option
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-container>
          <div class="modal-content">
            <v-row width="409px" height="78px">
              <v-col class="body-lg-semi-bold">
                <div data-cy="assign-question-modal-question">{{ question.questionText }}</div>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col cols="auto">
              <span class="label-md-light" data-cy="collaborator-select-label">COLLABORATOR</span>
              <v-select
                  data-cy="assign-question-collaborator-select"
                  id="assign-question-collaborator-select"
                  class="collaborator-select"
                  dense
                  flat
                  :items="teamMemberList"
                  :item-text="(item) => item.firstName + ' ' + item.lastName"
                  hide-details
                  single-line
                  return-object
                  solo
                  v-model="selectedTeamMember"
              >
                <template v-slot:label>
                  <label class="button-md" data-cy="assign-question-collaborator-select-label">
                    Select Collaborator</label>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <div class="label-md notes-label pb-1">NOTES</div>
              <v-textarea
                  data-cy="assign-question-modal-notes"
                  flat
                  class="assign-question-notes-text-area"
                  hide-details
                  solo
                  v-model="localPrimaryNoteVal"
              >
                <template v-slot:label>
                  <div class="body-md-light" data-cy="assign-question-modal-notes-label">
                    Additional information, instructions, etc.
                  </div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-row class="pl-7 mt-0 mb-6">
          <v-col cols="auto">
            <v-btn
                class="button-md-neutral navy-500-background"
                data-cy="assign-question-modal-assign-button"
                @click="assignQuestionToTeamMember()"
                dark
                :disabled="!selectedTeamMember"
            >Assign
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn
                class="button-md-neutral"
                outlined
                data-cy="assign-question-modal-cancel-button"
                @click="toggleAssignQuestionModal"
            >Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {Question} from '@/models/Question'
import {TeamMember} from '@/models/TeamMember'
import Component from 'vue-class-component'
import {Prop, Vue, Watch} from 'vue-property-decorator'
import {AssessmentStorageService} from '@/service/AssessmentStorageService'
import store from '@/store'
import {assignQuestions} from '@/utils/assign-question-utils'
import {Assessment} from '@/models/Assessment'

@Component
export default class AssignQuestionModal extends Vue {
  @Prop()
  isOpen!: boolean

  @Prop()
  question!: Question

  localShowAssignModalDialog = false

  localPrimaryNoteVal: string | null = null
  assessmentStorageService = new AssessmentStorageService()
  selectedTeamMember: TeamMember | null = null

  @Watch('isOpen')
  onIsOpenChange(val: boolean): void {
    this.localShowAssignModalDialog = val
  }

  get assessment(): Assessment {
    return store.getters.getAssessment
  }

  get teamMemberList(): Array<TeamMember> {
    return this.assessment?.teamMembers ?? []
  }

  @Watch('assessment')
  onAssessmentChange(): void {
    const foundAnswer = this.assessment.answers?.find(answer => answer.questionId === this.question.id)
    if (foundAnswer)
      this.localPrimaryNoteVal = foundAnswer.note
  }

  @Watch('question')
  onQuestionChange(): void {
    const currentlyAssignedTeamMember = this.teamMemberList.find(teamMember =>
        teamMember.collaboratorAssignments?.some(assignment => assignment.primaryQuestionId === this.question.id)
    )

    this.selectedTeamMember = currentlyAssignedTeamMember ?? null

    this.localPrimaryNoteVal = this.assessment.answers.find(answer => answer.questionId === this.question.id)?.note ?? null
  }

  toggleAssignQuestionModal(): void {
    this.selectedTeamMember = null
    this.localPrimaryNoteVal = null
    this.$parent?.$emit('toggleAssignQuestionModal')
  }

  async assignQuestionToTeamMember(): Promise<void> {
    if (this.localPrimaryNoteVal) {
      for (let answer of this.assessment.answers) {
        if (answer.questionId === this.question.id) {
          this.assessmentStorageService.saveAssessmentAnswer(this.question.id, answer.optionId, this.localPrimaryNoteVal??'')
          answer.note = this.localPrimaryNoteVal
          break
        }
      }
    }

    if (this.selectedTeamMember) {
      await assignQuestions(this.selectedTeamMember, [this.question])
    }

    this.localShowAssignModalDialog = false
    this.onIsOpenChange(false)
    this.toggleAssignQuestionModal()
  }

}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

.modal-header {
  height: 117px;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  background-color: $brand-shades-navy-50;
}

.modal-content {
  margin: 16px 16px 16px 0px;
}

.collaborator-select {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 2px solid $brand-shades-navy-100;
}

.assign-question-notes-text-area {
  width: 424px;
  /*height: 120px;*/
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid $brand-shades-navy-100;
}

.assign-button {
  display: inline-flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #00274e;
  background: $brand-shades-navy-500;
}

.cancel-button {
  display: inline-flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid $brand-shades-navy-500;
}

.notes-label {
  font-weight: 700 !important;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: $disabled-gray !important;
  color: black !important;
}
</style>
