<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M39.4165 0.918457H4.58317C2.55813 0.918457 0.916504 2.56008 0.916504 4.58512V39.4185C0.916504 41.4435 2.55813 43.0851 4.58317 43.0851H39.4165C41.4415 43.0851 43.0832 41.4435 43.0832 39.4185V4.58512C43.0832 2.56008 41.4415 0.918457 39.4165 0.918457Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.125 16.9601C15.1245 15.6632 15.4906 14.3925 16.1811 13.2947C16.8717 12.1969 17.8585 11.3167 19.0278 10.7557C20.197 10.1946 21.5011 9.97549 22.7895 10.1237C24.078 10.2718 25.2982 10.7812 26.3096 11.5931C27.321 12.4049 28.0823 13.4862 28.5056 14.712C28.9289 15.9379 28.997 17.2585 28.7021 18.5214C28.4072 19.7844 27.7613 20.9382 26.8388 21.8498C25.9164 22.7615 24.755 23.3938 23.4887 23.6737C23.0731 23.7557 22.6984 23.9781 22.4274 24.3035C22.1563 24.629 22.0054 25.0377 22 25.4612V27.4999"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.9998 32.0833C22.0905 32.0833 22.1791 32.1101 22.2545 32.1605C22.3298 32.2109 22.3886 32.2824 22.4233 32.3662C22.458 32.4499 22.467 32.5421 22.4494 32.631C22.4317 32.7199 22.388 32.8016 22.3239 32.8657C22.2598 32.9298 22.1782 32.9734 22.0893 32.9911C22.0003 33.0088 21.9082 32.9997 21.8244 32.965C21.7407 32.9303 21.6691 32.8716 21.6187 32.7962C21.5684 32.7208 21.5415 32.6322 21.5415 32.5416C21.5415 32.42 21.5898 32.3034 21.6757 32.2175C21.7617 32.1315 21.8783 32.0833 21.9998 32.0833Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'

@Component
export default class OverviewIcon extends Vue {
  get color(): string {
    return this.$route.name === 'RoadmapOverview' ? variables.navy500 : variables.navy100
  }
}
</script>
