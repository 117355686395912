<template>
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.177 16H15.154V6H18.177V16ZM13.1386 16H10.1155V3H13.1386V16ZM8.10011 16H5.07704V0H8.10011V16ZM3.06165 16H0.0385742V8H3.06165V16Z"
      fill="#00274E"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class BarChartIcon extends Vue {}
</script>
