import { AssessmentAnswer } from '@/models/AssessmentAnswer'
import { PolicyData } from '@/models/PolicyData'
import { TeamMember } from '@/models/TeamMember'

export class Assessment {
  answers!: AssessmentAnswer[]
  createdByFirstName!: string
  createdByLastName!: string
  createdByUsername!: string
  createdDateTime!: string
  id!: string
  industry!: string
  lastUpdatedByUsername!: string
  lastUpdatedDateTime!: string
  policyData!: PolicyData[]
  teamMembers!: TeamMember[]
}
