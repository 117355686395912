<template>
  <v-sheet elevation="8">
    <v-container id="slim-product-offering" :data-cy="idPrefix">
      <v-row id="header-title" class="mt-3" justify="center">
        <v-col class="pb-0" cols="auto">
          <div class="header-sm" :data-cy="`${idPrefix}-title`">{{ title }}</div>
        </v-col>
      </v-row>
      <v-row id="header-history-text" class="mt-3" justify="center" v-if="showHeaderHistoryText">
        <v-col class="pb-0" cols="auto">
          <div class="label-md-light" :data-cy="`${idPrefix}-history-text`">{{ historyText }}</div>
        </v-col>
      </v-row>
      <v-row id="header-duration" align="center" justify="center" v-if="showHeaderDuration">
        <v-col align="center" class="pa-1" cols="auto">
          <v-icon :data-cy="idPrefix + '-subtitle-icon'" size="20">$vuetify.icons.clock</v-icon>
        </v-col>
        <v-col align="center" class="pa-1 pt-2" cols="auto">
          <div :data-cy="idPrefix + '-subtitle'" class="body-sm">{{ duration }}</div>
        </v-col>
      </v-row>
      <v-row id="product-image-section" v-if="showProductImageSection" justify="center">
        <v-col cols="auto">
          <v-img
            v-if="showProductImage"
            :alt="altImageText"
            contain
            class="clickable"
            @click="navToPage"
            :data-cy="idPrefix + '-image'"
            :src="require('@/assets/landing-page/' + image + '')"
          />
          <product-offering-nist-score v-if="showNistScore" :key="renderKey" :user-preference="userPreferences">
          </product-offering-nist-score>
        </v-col>
      </v-row>
      <v-row id="company-score-section" v-if="showCompanyScore" align="center" justify="center">
        <v-col class="label-md-neutral" cols="auto">
          <v-row justify="center">
            <v-col
              cols="auto"
              :data-cy="`${idPrefix}-score-text-1`"
              :class="`py-0 score-text--${userPreferences.companyScore.grade.toLowerCase()}`"
              >YOUR</v-col
            >
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              :data-cy="`${idPrefix}-score-text-2`"
              :class="`py-0 score-text--${userPreferences.companyScore.grade.toLowerCase()}`"
              >SCORE</v-col
            >
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-img
            contain
            class="clickable"
            @click="navToPage"
            :data-cy="idPrefix + '-grade-image'"
            :src="require('@/assets/scorecard-grades/factor_' + userPreferences.companyScore.grade.toLowerCase() + '.svg')"
          />
        </v-col>
        <v-col
          :data-cy="`${idPrefix}-score`"
          :class="`header-md-neutral score-text--${userPreferences.companyScore.grade.toLowerCase()}`"
          cols="auto"
          >{{ userPreferences.companyScore.score }}</v-col
        >
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <div class="label-md" :data-cy="idPrefix + '-desktop-disclaimer'">OPTIMIZED FOR DESKTOP VIEWING</div>
        </v-col>
      </v-row>
      <v-row v-if="!historyText" class="mb-4" justify="center">
        <v-col cols="auto">
          <v-btn
            class="button-md-neutral navy-500-background"
            dark
            :data-cy="idPrefix + '-get-started-button'"
            @click="handleGetStarted"
            >{{ productType === 'insights' ? 'Download' : 'Get started' }}</v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn class="button-md" @click="togglePreview" :data-cy="idPrefix + '-preview-button'" outlined
            >See preview</v-btn
          >
        </v-col>
      </v-row>
      <v-row id="button-row-with-history" v-else class="mb-4" align="center" justify="center">
        <v-col cols="auto">
          <v-btn
            class="button-md-neutral navy-500-background"
            dark
            :data-cy="idPrefix + '-get-started-button'"
            @click="handleGetStarted"
            >{{ startButtonText }}</v-btn
          >
        </v-col>
        <v-col cols="auto" v-if="showButtonRowDurationSection">
          <v-row id="button-row-duration-section" align="center" justify="center">
            <v-col align="center" class="pa-1" cols="auto">
              <v-icon :data-cy="idPrefix + '-subtitle-icon'" size="20">$vuetify.icons.clock</v-icon>
            </v-col>
            <v-col align="center" class="pa-1 pt-2" cols="auto">
              <div :data-cy="idPrefix + '-subtitle'" class="body-sm">{{ duration }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="auto">
          <v-btn class="button-md" @click="togglePreview" :data-cy="idPrefix + '-preview-button'" outlined
            >See preview</v-btn
          >
        </v-col>
      </v-row>
      <v-row id="footer-section" justify="center">
        <v-col class="product-offering-footer" cols="auto">
          <div class="body-md text-align-center pa-2" :data-cy="idPrefix + '-footer-text'">{{ footerText }}</div>
          <div class="body-sm-light text-align-center" :data-cy="idPrefix + '-footer-subtext'" v-if="footerSubtext">
            {{ footerSubtext }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import ProductOfferingNistScore from '@/components/ProductOfferingNistScore.vue'
import store from '@/store'
import { DateUtils } from '@/utils/date-utils'
import { Prop, Vue } from 'vue-property-decorator'
import { UserPreferences } from '@/models/UserPreferences'

@Component({
  components: { ProductOfferingNistScore }
})
export default class ProductOfferingMobile extends Vue {
  @Prop()
  altImageText!: string

  @Prop()
  dataCyPrefix!: string

  @Prop()
  duration!: string

  @Prop()
  image!: string

  @Prop()
  footerText!: string

  @Prop()
  footerSubtext!: string

  @Prop()
  getStartedRoute!: string

  @Prop()
  historyTextPrefix!: string

  @Prop()
  title!: string

  @Prop()
  productType!: string

  dateUtils = new DateUtils()
  renderKey = 0

  get showButtonRowDurationSection(): boolean {
    if (this.productType !== 'assessment') {
      return !!this.historyText
    }

    return !!this.userPreferences.nistMaturityScore
  }

  get historyText(): string {
    let previousDate = ''
    let text = ''
    switch (this.productType) {
      case 'insights':
        previousDate = this.userPreferences.lastViewedInsights
        break
      case 'scorecard':
        previousDate = this.userPreferences.lastViewedScorecard
        break
      case 'assessment':
        previousDate = this.userPreferences.lastUpdatedAssessment
        break
    }

    if (previousDate) {
      text = this.historyTextPrefix + this.dateUtils.getLastVisitedDateString(previousDate)
    }

    return text
  }

  get idPrefix(): string {
    return 'mobile-' + this.dataCyPrefix + this.productType
  }

  get showCompanyScore(): boolean {
    if (!this.userPreferences.companyScore || !this.historyText) {
      return false
    }

    return this.userPreferences.companyScore.grade != null && this.productType === 'scorecard'
  }

  get showHeaderDuration(): boolean {
    return !this.historyText || (this.productType === 'assessment' && !this.userPreferences.nistMaturityScore)
  }

  get hasCompletedAssessment(): boolean {
    if (this.productType !== 'assessment') return false

    return !!this.userPreferences.nistMaturityScore
  }

  get showHeaderHistoryText(): boolean {
    return (!!this.historyText && this.productType !== 'assessment') || this.hasCompletedAssessment
  }

  get showProductImage(): boolean {
    return (
      !this.historyText ||
      (this.productType === 'assessment' && !this.userPreferences.nistMaturityScore) ||
      this.productType === 'insights'
    )
  }

  get showProductImageSection(): boolean {
    return !this.showCompanyScore || this.productType !== 'scorecard'
  }

  get showNistScore(): boolean {
    return !!this.historyText && this.productType === 'assessment' && !!this.userPreferences.nistMaturityScore
  }

  get startButtonText(): string {
    let text = ''
    switch (this.productType) {
      case 'insights':
        text = 'Download'
        break
      case 'scorecard':
        text = 'Reassess'
        break
      case 'assessment':
        text = this.userPreferences.nistMaturityScore ? 'Reassess' : 'Resume'
        break
    }
    return text
  }

  get userPreferences(): UserPreferences {
    return store.getters.getUserPreferences
  }

  handleGetStarted(): void {
    if (this.productType === 'insights') {
      const url = store.getters.getPersonalChecklistURL
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'personal-checklist.pdf')
      link.click()
    } else {
      this.$router.push(this.getStartedRoute)
    }
  }

  navToPage(): void {
    this.$router.push(this.getStartedRoute)
  }

  togglePreview(): void {
    this.$emit('togglePreview', this.productType)
  }

  mounted(): void {
    this.renderKey++
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

.product-offering-footer {
  background-color: $brand-shades-navy-50;
  min-height: 100px;
}

.score-text {
  &--a {
    color: $scorecard-grade-a;
  }
  &--b {
    color: $scorecard-grade-b;
  }
  &--c {
    color: $scorecard-grade-c;
  }
  &--d {
    color: $scorecard-grade-d;
  }
  &--f {
    color: $scorecard-grade-f;
  }
}

.your-score-text {
  margin-left: -6px;
}
</style>
