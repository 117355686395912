import BarChartIcon from '@/components/icons/BarChartIcon.vue'
import CaretDownIcon from '@/components/icons/CaretDownIcon.vue'
import CaretUpIcon from '@/components/icons/CaretUpIcon.vue'
import CheckMarkButtonIcon from '@/components/icons/CheckMarkButtonIcon.vue'
import ClockIcon from '@/components/icons/ClockIcon.vue'
import DetectIcon from '@/components/icons/DetectIcon.vue'
import DisabledLinkIcon from '@/components/icons/DisabledLinkIcon.vue'
import DownloadIcon from '@/components/icons/DownloadIcon.vue'
import FilterIcon from '@/components/icons/FilterIcon.vue'
import GovernIcon from '@/components/icons/GovernIcon.vue'
import IdentifyIcon from '@/components/icons/IdentifyIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import LinkIcon from '@/components/icons/LinkIcon.vue'
import MailIcon from '@/components/icons/MailIcon.vue'
import NextCategoryIcon from '@/components/icons/NextCategoryIcon.vue'
import NextFunctionNavIcon from '@/components/icons/NextFunctionNavIcon.vue'
import OptionSelectDefaultIcon from '@/components/icons/OptionSelectDefaultIcon.vue'
import OverviewIcon from '@/components/icons/OverviewIcon.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'
import PreviousCategoryIcon from '@/components/icons/PreviousCategoryIcon.vue'
import PreviousFunctionNavIcon from '@/components/icons/PreviousFunctionNavIcon.vue'
import ProtectIcon from '@/components/icons/ProtectIcon.vue'
import RecoverIcon from '@/components/icons/RecoverIcon.vue'
import RespondIcon from '@/components/icons/RespondIcon.vue'
import ResultsIcon from '@/components/icons/ResultsIcon.vue'
import UserIcon from '@/components/icons/UserIcon.vue'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify, {})

export default new Vuetify({
  theme: {
    //we are using runyon custom design / theme
    disable: true
  },
  icons: {
    values: {
      barChart: {
        component: BarChartIcon
      },
      caretDown: {
        component: CaretDownIcon
      },
      caretUp: {
        component: CaretUpIcon
      },
      checkMarkButton: {
        component: CheckMarkButtonIcon
      },
      clock: {
        component: ClockIcon
      },
      detect: {
        component: DetectIcon
      },
      download: {
        component: DownloadIcon
      },
      filter: {
        component: FilterIcon
      },
      govern: {
        component: GovernIcon
      },
      previousFunctionNav: {
        component: PreviousFunctionNavIcon
      },
      identify: {
        component: IdentifyIcon
      },
      info: {
        component: InfoIcon
      },
      nextFunctionNav: {
        component: NextFunctionNavIcon
      },
      link: {
        component: LinkIcon
      },
      link_disabled: {
        component: DisabledLinkIcon
      },
      mail: {
        component: MailIcon
      },
      nextCategory: {
        component: NextCategoryIcon
      },
      optionSelectDefault: {
        component: OptionSelectDefaultIcon
      },
      overview: {
        component: OverviewIcon
      },
      pencil: {
        component: PencilIcon
      },
      phone: {
        component: PhoneIcon
      },
      previousCategory: {
        component: PreviousCategoryIcon
      },
      protect: {
        component: ProtectIcon
      },
      recover: {
        component: RecoverIcon
      },
      respond: {
        component: RespondIcon
      },
      results: {
        component: ResultsIcon
      },
      user: {
        component: UserIcon
      }
    }
  }
})
