<template>
  <v-footer
    class="footer mt-4"
    data-cy="footer"
    :max-height="isMobile ? '' : isAuthenticated ? '300px' : '56px'"
    :min-height="isMobile ? '' : isAuthenticated ? '300px' : '56px'"
  >
    <v-container class="main-content-container">
      <v-row v-if="isAuthenticated" data-cy="auth-footer" justify="center">
        <v-col cols="5">
          <v-row justify="start">
            <v-col cols="auto">
              <v-img
                alt="GAIG Logo"
                class="shrink mr-2"
                contain
                data-cy="footer-company-header-logo"
                src="../assets/logo-gaig-blue.svg"
                transition="scale-transition"
                width="175"
              />
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col class="body-md" cols="auto">
              <span data-cy="footer-ga-text">{{ footerGaText }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer />
        <v-col cols="3">
          <v-row justify="start">
            <v-col cols="auto">
              <span class="header-xs" data-cy="footer-helpful-links-title">Helpful Links</span>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col class="pb-2" cols="auto">
              <div class="link-sm">
                <a data-cy="footer-eRiskHub-link" href="https://eriskhub.com/greatamerican" target="_blank"
                  >Great American eRiskHub</a
                >
              </div>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col class="pb-2" cols="auto">
              <div class="link-sm">
                <a
                  data-cy="footer-cyber-ins-link"
                  href="https://www.greatamericaninsurancegroup.com/for-businesses/division-details/cyber-risk-insurance"
                  target="_blank"
                  >Cyber Risk Insurance</a
                >
              </div>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col class="pb-2" cols="auto">
              <div class="link-sm">
                <a data-cy="footer-about-ga-link" href="https://www.greatamericaninsurancegroup.com/about-us" target="_blank"
                  >About Great American</a
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row justify="start">
            <v-col cols="auto">
              <span class="header-xs" data-cy="footer-address-title">Corporate Headquarters</span>
            </v-col>
          </v-row>
          <v-row class="mt-4" justify="start" no-gutters>
            <v-col cols="auto">
              <span class="body-sm" data-cy="footer-address-line1">Great American Insurance Group Tower</span>
            </v-col>
          </v-row>
          <v-row justify="start" no-gutters>
            <v-col cols="auto">
              <span class="body-sm" data-cy="footer-address-line2">301 E. Fourth St.</span>
            </v-col>
          </v-row>
          <v-row justify="start" no-gutters>
            <v-col cols="auto">
              <span class="body-sm" data-cy="footer-address-line3">Cincinnati, OH 45202</span>
            </v-col>
          </v-row>
          <v-row class="mt-1" justify="start">
            <v-col cols="auto">
              <span class="body-sm" data-cy="footer-phone">800-545-4269 / 513-369-5000</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider v-if="isAuthenticated" class="ma-4" />
      <v-row data-cy="base-footer">
        <v-col cols="auto">
          <div class="link-xs">
            <a
              data-cy="legal-disclosures-link"
              href="https://www.greatamericaninsurancegroup.com/contact/legal-disclosures"
              target="_blank"
              >Legal Disclosures/Terms and Conditions</a
            >
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="link-xs">
            <a data-cy="privacy-link" href="https://www.greatamericaninsurancegroup.com/contact/privacy" target="_blank"
              >Privacy</a
            >
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="link-xs">
            <a
              data-cy="cookie-policy-link"
              href="https://www.greatamericaninsurancegroup.com/contact/privacy/cookie-policy"
              target="_blank"
              >Cookie Policy</a
            >
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="link-xs">
            <a
              data-cy="service-marks-link"
              href="https://www.greatamericaninsurancegroup.com/docs/default-source/default-document-library/gaig-pcservice-marks.pdf"
              target="_blank"
              >Service Marks</a
            >
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="link-xs">
            <a
              data-cy="accessibility-link"
              href="https://www.greatamericaninsurancegroup.com/contact/accessibility"
              target="_blank"
              >Accessibility</a
            >
          </div>
        </v-col>
        <v-spacer />
        <v-spacer />
        <v-col cols="auto">
          <div class="body-xs" data-cy="copy-right">
            ©{{ new Date().getFullYear().toString() }} Great American Insurance Company. All Rights Reserved.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'

@Component
export default class AppFooter extends Vue {
  @Prop({ type: Boolean })
  isAuthenticated!: boolean

  @Prop({ type: Boolean })
  isMobile!: boolean

  footerGaText =
    "Great American knows protection.  We offer the same tools we use to protect ourselves to protect our policyholders.  When you're secure, we're secure."
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';
.footer {
  background-color: $brand-shades-navy-100 !important;
}
</style>
