import { Assessment } from '@/models/Assessment'
import { AssessmentProgress } from '@/models/AssessmentProgress'

interface IAssessmentState {
  assessment: Assessment
  assessmentProgress: AssessmentProgress
  assessmentSaveDisabled: boolean
  editQuestionId: number
}
const state: IAssessmentState = {
  assessment: new Assessment(),
  assessmentProgress: new AssessmentProgress(),
  assessmentSaveDisabled: false,
  editQuestionId: 0
}

const getters = {
  getEditQuestionId: (state: IAssessmentState): number => {
    return state.editQuestionId
  },
  getAssessment: (state: IAssessmentState): Assessment => {
    return state.assessment
  },
  getAssessmentSaveDisabled: (state: IAssessmentState): boolean => {
    return state.assessmentSaveDisabled
  },
  getAssessmentProgress: (state: IAssessmentState): AssessmentProgress => {
    return state.assessmentProgress
  }
}
const mutations = {
  setEditQuestionId(state: IAssessmentState, params: number): void {
    state.editQuestionId = params
  },
  setAssessment(state: IAssessmentState, params: Assessment): void {
    state.assessment = params
  },
  setAssessmentSaveDisabled(state: IAssessmentState, params: boolean): void {
    state.assessmentSaveDisabled = params
  },
  setAssessmentProgress(state: IAssessmentState, params: AssessmentProgress): void {
    state.assessmentProgress = params
  }
}

export default {
  state,
  getters,
  mutations
}
