import store from '@/store/index'
import {AssessmentAnswer} from '@/models/AssessmentAnswer'
import {AssessmentStorageService} from '@/service/AssessmentStorageService'
import {Question} from '@/models/Question'
import {TeamMember} from '@/models/TeamMember'
import {findQuestionById} from '@/utils/question-utils'

const assessmentStorageService = new AssessmentStorageService()

async function assignQuestions(teamMember: TeamMember, questions: Question[]): Promise<void> {
    if (!questions || questions.length === 0) {
        console.error(`No questions to assign! Questions: ${questions}, TeamMember: ${teamMember}`)
        return
    }

    const assessment = store.getters.getAssessment
    for (const question of questions) {
        const isAssigned = assessment.teamMembers.some(member =>
            member.collaboratorAssignments.some(assignment => assignment.primaryQuestionId === question.id)
        )

        if (isAssigned) {
            await unassignPrimaryQuestion(question.id)
        }

        if (!question.primaryQuestionId) {
            teamMember.collaboratorAssignments.push({primaryQuestionId: question.id, submitted: false, submittedDate: ''})
        }

        if (!teamMember.answers) {
            teamMember.answers = []
        }

        for (const answer of assessment.answers) {
            if (isRelatedAnswer(question, answer)) {
                teamMember.answers.push(answer)
            }
        }

        const existingTeamMemberIndex = assessment.teamMembers.findIndex(tm => tm.email === teamMember.email)
        if (existingTeamMemberIndex !== -1) {
            assessment.teamMembers[existingTeamMemberIndex] = teamMember
        } else {
            assessment.teamMembers.push(teamMember)
        }
    }

    await assessmentStorageService.updateAssessment(assessment)
}

function isRelatedAnswer(question: Question, answer: AssessmentAnswer): boolean {
    return answer.questionId === question.id ||
        question.followUpQuestions?.some(followUp => followUp.id === answer.questionId) ||
        false
}

async function unassignPrimaryQuestion(questionId: number): Promise<void> {
    const assessment = store.getters.getAssessment
    const question = findQuestionById(questionId)

    assessment.teamMembers = assessment.teamMembers.map((teamMember) => {
        teamMember.collaboratorAssignments = teamMember.collaboratorAssignments.filter(assignment => assignment.primaryQuestionId != questionId)

        teamMember.answers = teamMember.answers.filter(answer =>
            answer.questionId != questionId &&
            !question?.followUpQuestions?.some(followUp => followUp.id === answer.questionId)
        )

        return teamMember
    })

    await assessmentStorageService.updateAssessment(assessment)
}

export {
    assignQuestions,
    unassignPrimaryQuestion
}