import axiosInstance from '@/utils/axios-instance'
import { Assessment } from '@/models/Assessment'
import store from '@/store'
import {DeleteTeamMemberResponse} from '@/models/DeleteTeamMemberResponse'

function addTeamMemberToIDV(email: string, firstName: string, lastName: string): Promise<Assessment> {
    return axiosInstance.post('/api/team-member/add', {
        emailAddress: email,
        firstName: firstName,
        lastName: lastName
    }).then(response => {
        const assessment = response?.data
        if (assessment) {
            store.commit('setAssessment', assessment)
        }
        return assessment
    }).catch(error => {
        const errorResponse = error?.response
        console.error('Error adding team member to IDV:', errorResponse || error)
        throw error
    })
}

function deleteTeamMemberFromIDV(email: string): Promise<DeleteTeamMemberResponse> {
    return axiosInstance.post('/api/team-member/delete', null, {
        params: { email }
    }).then(response => {
        return response.data
    }).catch(error => {
        const errorResponse = error?.response
        console.error('Error deleting team member from IDV:', errorResponse || error)
        throw error
    })
}

export { addTeamMemberToIDV, deleteTeamMemberFromIDV }