<template>
  <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 5L10 0L0 0L5 5Z" fill="#00274E" />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class CaretDownIcon extends Vue {}
</script>
