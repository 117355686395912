import { QuestionOption } from '@/models/QuestionOption'

export class Question {
  categoryId!: number
  followUpQuestions!: Question[]
  functionId!: number
  id!: number
  options!: QuestionOption[]
  primaryQuestionId!: number
  questionText!: string
  retired!: boolean
  tppDescription!: string
  tppId!: number
  tppName!: string
  type!: string
}