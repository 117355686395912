<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5951 19.006H15.5671C11.7254 18.9115 8.05937 17.3775 5.2951 14.708C2.62462 11.9463 1.08979 8.28159 0.994984 4.44103C0.989327 3.90303 1.20056 3.38539 1.5811 3.00503L4.2951 0.293031C4.6856 -0.0973513 5.3186 -0.0973513 5.7091 0.293031L9.7091 4.29303C10.0995 4.68353 10.0995 5.31653 9.7091 5.70703L8.1131 7.30003C8.40911 8.40595 8.95437 9.42943 9.7071 10.292C10.5681 11.045 11.5903 11.5907 12.6951 11.887L14.2951 10.293C14.6856 9.90265 15.3186 9.90265 15.7091 10.293L19.7091 14.293C20.0995 14.6835 20.0995 15.3165 19.7091 15.707L16.9951 18.42C16.625 18.7939 16.1212 19.0048 15.5951 19.006ZM5.0001 2.41503H4.9951L2.9951 4.41903C3.07664 7.73769 4.40142 10.9047 6.7071 13.293C9.09553 15.5979 12.2619 16.9226 15.5801 17.005L17.5871 15L14.9951 12.415L13.7021 13.707C13.5171 13.8941 13.2652 13.9995 13.0021 14C12.9319 14.0001 12.8618 13.9927 12.7931 13.978C11.1267 13.6094 9.57949 12.8286 8.2931 11.707C7.17155 10.4206 6.39071 8.8734 6.0221 7.20703C5.95256 6.8758 6.05529 6.53187 6.2951 6.29303L7.5871 5.00003L5.0001 2.41503Z"
      fill="#00274E"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PhoneIcon extends Vue {}
</script>
