<template>
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.288086 6.49999L6.29809 12.51L7.71209 11.096L3.11209 6.49599L7.71209 1.89599L6.29809 0.48999L0.288086 6.49999Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import variables from '@/styles/_export.module.scss'

@Component
export default class PreviousCategoryIcon extends Vue {
  get iconColor(): string {
    return variables.navy500
  }
}
</script>
