<template>
  <v-container data-cy="landing-page" fluid>
    <v-row v-if="isMobile" justify="center">
      <v-col cols="auto">
        <v-img
          alt="GAIG Logo"
          class="shrink mr-2 clickable"
          contain
          data-cy="mobile-company-header-logo"
          src="@/assets/eagle-eye-logo.svg"
          transition="scale-transition"
          width="200px"
        />
      </v-col>
    </v-row>
    <v-row class="mt-4" justify="center">
      <v-col class="pb-0" cols="auto">
        <div :class="`${isMobile ? 'header-md' : 'header-xxl'} text-align-center`" data-cy="landing-page-title-1">
          {{ title1 }}
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="mt-1" cols="10">
        <div :class="`${isMobile ? 'body-md' : 'body-xl'} text-align-center`" data-cy="landing-page-subtitle-1">
          {{ subtitle1 }}
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-3" justify="center">
      <v-col class="pa-0" cols="8">
        <div :class="`${isMobile ? 'header-sm' : 'header-lg'} text-align-center mt-9`" data-cy="landing-page-title-2">
          {{ title2 }}
        </div>
      </v-col>
    </v-row>
    <v-row class="pb-2" justify="center">
      <v-col cols="8">
        <div :class="`${isMobile ? 'body-sm' : 'body-lg'} text-align-center`" data-cy="landing-page-subtitle-2">
          {{ subtitle2 }}
        </div>
      </v-col>
    </v-row>
    <div class="product-offering-container pb-3" v-if="!isMobile">
      <product-offering-three-wide
        :key="i"
        v-for="(product, i) in productOfferings"
        class="product-offering"
        :altImageText="product.altImageText"
        :duration="product.duration"
        :footerSubtext="product.footerSubtext"
        :footerText="product.footerText"
        :getStartedRoute="
          user.collaborator && product.productType !== 'abira-security' ? { name: 'Welcome' } : product.getStartedRoute
        "
        :external-route="product.externalRoute"
        :history-text-prefix="product.historyTextPrefix"
        :image="product.image"
        :previewImage="product.previewImage"
        :productType="product.productType"
        :title="product.title"
        data-cy-prefix="landing-page-"
        preview
        v-on:togglePreview="togglePreview"
      />
    </div>
    <section id="mobile-products" v-else>
      <v-row :key="i" v-for="(product, i) in productOfferings" justify="center">
        <v-col cols="auto">
          <product-offering-mobile
            :altImageText="product.altImageText"
            :duration="product.duration"
            :footerSubtext="product.footerSubtext"
            :footerText="product.footerText"
            :getStartedRoute="product.getStartedRoute"
            :history-text-prefix="product.historyTextPrefix"
            :image="product.image"
            :previewImage="product.previewImage"
            :productType="product.productType"
            :title="product.title"
            data-cy-prefix="landing-page-"
            preview
            v-on:togglePreview="togglePreview"
          ></product-offering-mobile>
        </v-col>
      </v-row>
    </section>
    <v-row class="pt-2" justify="center">
      <v-col cols="8">
        <div class="header-sm text-align-center text-uppercase" data-cy="landing-page-title-3">{{ title3 }}</div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="pa-0 mt-7" cols="9">
        <div :class="`${isMobile ? 'body-sm-light' : 'body-lg-light'} text-align-center`" data-cy="landing-page-subtitle-3">
          {{ subtitle3 }}
        </div>
      </v-col>
    </v-row>
    <preview-modal
      :is-open="isPreviewOpen"
      :preview-image="currentPreviewImage"
      :product-type="currentProductType"
      v-on:togglePreview="togglePreview"
    ></preview-modal>
  </v-container>
</template>

<script lang="ts">
import ClockIcon from '@/components/icons/ClockIcon.vue'
import Component from 'vue-class-component'
import PreviewModal from '@/components/PreviewModal.vue'
import ProductOfferingMobile from '@/components/ProductOfferingMobile.vue'
import ProductOfferingModel from '@/models/ProductOffering'
import ProductOfferingThreeWide from '@/components/ProductOfferingThreeWide.vue'

import Vue from 'vue'
import store from '@/store'
import { User } from '@/models/User'

@Component({
  components: { ProductOfferingMobile, ClockIcon, PreviewModal, ProductOfferingThreeWide }
})
export default class LandingPage extends Vue {
  currentPreviewImage = ''
  currentProductType = ''
  isPreviewOpen = false
  productOfferings = store.getters.getProductOfferings as ProductOfferingModel[]
  subtitle1 =
    'Great American knows protection. We offer the same tools we use to protect ourselves to protect our policyholders. When you’re secure, we’re secure.'
  subtitle2 =
    'Our tools, based on the Five Functions of NIST’s Cybersecurity Framework, find strengths and weaknesses in your cyber defenses.'
  subtitle3 =
    "Note: The questions in the Security Roadmap are not part of your application for insurance and your answers will not affect your policy's terms and conditions."
  title1 = 'Find your cybersecurity vulnerability first'
  title2 = 'Avoid costly losses of data, access, and reputation'
  title3 = 'Included with the purchase of a great american cyber risk policy'

  togglePreview(type: string): void {
    const currentProduct = this.productOfferings.filter((p) => p.productType === type)[0]
    this.currentProductType = type
    this.currentPreviewImage = currentProduct.previewImage

    this.isPreviewOpen = !this.isPreviewOpen
  }

  get isMobile(): boolean {
    const breakpoint = this.$vuetify.breakpoint
    return breakpoint.width < 767
  }

  get user(): User {
    return store.getters.getCurrentUser
  }
}
</script>

<style scoped lang="scss">
.product-offering-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.product-offering {
  flex-basis: 40%;
}
</style>
