<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.05396 19H0.0385742V18C0.0430168 14.1358 3.19853 11.0044 7.09242 11H11.1232C15.0171 11.0044 18.1726 14.1358 18.177 18V19H16.1617V18C16.1583 15.2399 13.9045 13.0033 11.1232 13H7.09242C4.31114 13.0033 2.05729 15.2399 2.05396 18V19ZM9.10781 10C6.32514 10 4.06934 7.76142 4.06934 5C4.06934 2.23858 6.32514 0 9.10781 0C11.8905 0 14.1463 2.23858 14.1463 5C14.1429 7.76005 11.8891 9.99669 9.10781 10ZM9.10781 2C7.43821 2 6.08473 3.34315 6.08473 5C6.08473 6.65685 7.43821 8 9.10781 8C10.7774 8 12.1309 6.65685 12.1309 5C12.1309 3.34315 10.7774 2 9.10781 2Z"
      fill="#00274E"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class UserIcon extends Vue {}
</script>
