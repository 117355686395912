export class User {
  authenticated = false
  authorized = false
  email!: string
  firstName!: string
  lastName!: string
  timestamp!: string
  username!: string
  collaborator = false
}
