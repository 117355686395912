import { CyberRiskError } from '@/models/CyberRiskError'

interface IExceptionState {
  cyberRiskError: CyberRiskError
}

const state: IExceptionState = {
  cyberRiskError: new CyberRiskError()
}

const getters = {
  getCyberRiskError: (state: IExceptionState): CyberRiskError => {
    return state.cyberRiskError
  }
}

const mutations = {
  setCyberRiskError: (state: IExceptionState, params: CyberRiskError): void => {
    state.cyberRiskError = params
  }
}

export default {
  getters,
  mutations,
  state
}
